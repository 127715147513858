import React, { useEffect, useState } from 'react';
import '../App.css';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Space, Image } from 'antd';
import FertButton from '../component/FertButton';
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';
import FertLogo from '../images/logo FertiliFind.png';
const { Header } = Layout;

const headerStyle: React.CSSProperties = {
  backgroundColor: '#FFFFFF',
};

export default function AppHeader() {
  const loc = useLocation();
  const [currentPage, setCurrentPage] = useState<string>('');

  useEffect(() => {
    const fullPath = loc.pathname as string;
    let path = fullPath.startsWith('/') ? fullPath.substring(1) : fullPath;
    path = path.substring(path.indexOf('/'));

    setCurrentPage(path);
  }, [loc.pathname]);

  return (
    <Header style={headerStyle} className='app-header'>
      <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'start' }}>
        {/* Logo */}
        <Space>
          <Link to="/">
            <Image
              className='app-header-logo'
              preview={false}
              src={FertLogo}
            />
          </Link>
        </Space>

        {/* Button list */}
        <Space direction='horizontal' size={4} className='app-header-buttons'>
          {/* <FertButton type='text'>Provider Directory</FertButton>
          <FertButton type='text'>Funding Your Family</FertButton>
          <FertButton type='text'>FAQ</FertButton>
          <FertButton type='text'>Sponsor</FertButton> */}
          {
            currentPage !== 'search' && (
              <Link to={`search`}>
                <FertButton type='primary' icon={<SearchOutlined />} style={{ padding: '0.3em 1.8em 1.9em 1.4em' }}>Search Providers</FertButton>
              </Link>
            )
          }
        </Space>
      </Space>
    </Header>
  );
}