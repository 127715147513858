import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { atom } from 'jotai'
//import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ProviderPage from './view/provider/ProviderPage';
import HomePage from './view/HomePage';
import SearchPage from './view/search/SearchPage';
import AboutUsPage from './view/AboutUsPage';
//import Maps from './view/maps/MapsPage';

const reactRouteObjects = [
  {
    path: "",
    element: <HomePage />,
  },
  {
    path: "provider/:main_id",
    element: <ProviderPage />,
  },
  {
    path: "search",
    element: <SearchPage />,
  },
  {
    path: "about",
    element: <AboutUsPage />,
  },
  // {
  //   path: "maps",
  //   element: <Maps />
  // }
];

const routeNamesAtom = atom<string[]>(reactRouteObjects.map(route => route.path));
export { routeNamesAtom };

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: reactRouteObjects,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
