import React, { useEffect, useMemo, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { useParams } from 'react-router-dom';
import { Provider, selectedProviderAtom, allProvidersAtom, isBackToTopBtnVisibleAtom, SlimSearchProvider, isAppLoadingAtom, isSmallScreenAtom } from '../../atoms';
import '../../App.css';
import './ProviderPage.css';
import { Button, Divider, Image } from 'antd';
import ImageWithMultiText from '../../component/ImageWithMultiText';
import FertButton from '../../component/FertButton';
import FertSpace from '../../component/FertSpace';
import DetailSection from './DetailSection';
import SuccessRateSection from './SuccessRateSection';
import OtherOfficesSection from './OtherOfficesSection';
import FinancialSection from './FinancialSection';
import ContactForm from './ContactForm';
import ProviderBanner from '../../images/provider_banner.png';
import ProviderBannerPremiumGainsville from '../../images/premium_page_header.jpg';
import GainsvilleBlogCard1 from '../../images/premium_profile_gainsville.png';
import GainsvilleBlogCard2 from '../../images/premium_profile_gainsville_blog_2.png';
import GainsvilleBlogCard3 from '../../images/premium_profile_gainsville_blog_3.png';
import GainsvilleSetsApart from '../../images/premium_profile_gainsville_apart_1.png';
import { ArrowUpOutlined } from '@ant-design/icons';

const contentStyle: React.CSSProperties = {
  textAlign: 'center',
  minHeight: 120,
  backgroundColor: '#FFFFFF',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
};

export default function ProviderPage() {
  const [provider, setProvider] = useAtom(selectedProviderAtom);
  const [relatedProviders, setRelatedProviders] = useState<SlimSearchProvider[]>([]);
  const [isAppLoading, setIsAppLoading] = useAtom(isAppLoadingAtom);
  const isSmallScreen = useAtomValue(isSmallScreenAtom);
  const allProviders = useAtomValue(allProvidersAtom);
  const isBackToTopBtnVisible = useAtomValue(isBackToTopBtnVisibleAtom);
  const params = useParams();

  const headerContentStyle:React.CSSProperties = useMemo(() => ({
    fontSize: isSmallScreen ? '24px' : '36px',
    fontWeight: 600,
    marginBottom: isSmallScreen ? '1.5rem' : '3rem',
    textAlign: 'center',
    fontFamily: '"Figtree", serif',
  }), [isSmallScreen]);

  //TODO: don't call back end unless needed?
  useEffect(() => {
    setIsAppLoading(true);

    try {
      fetch('/api/getProviderByMainId?main_id=' + params.main_id).then(resp => resp.json().then(data => {
        if(!data.error) {
          const thisProvider:Provider = data;
          setProvider(thisProvider);
  
          fetch('/api/getProvidersByProviderId?provider_id=' + data.provider_id).then(resp => resp.json().then(subData => {
            if(!subData.error) {
              setRelatedProviders(subData.filter((prov: SlimSearchProvider) => prov.fertilifind_main_id !== thisProvider.fertilifind_main_id));
            }

            setIsAppLoading(false);
          }));
        }
      }));
    }
    catch(e) {
      //TODO: display error message
      console.error(e);
      setIsAppLoading(false);
    }

    if(allProviders && allProviders.length) {
      window.scrollTo({ top: 0 });
    }
  }, [params, setProvider]);

  const getBlogCards = () => {
    const textCss = isSmallScreen ? {
      color: 'rgba(0, 0, 0, 0.75)',
      whiteSpace: 'pre-line',
      fontFamily: '"Public Sans", sans-serif',
    } : null;

    return provider.is_premium && provider.fertilifind_main_id === '0b58c7de-d392-4ef0-8c13-9790f3ee61dd' ? (
      <>
        <ImageWithMultiText
          imgSrc={GainsvilleBlogCard1}
          width={300}
          boldText={'IVF Procedure'}
          textStyle={textCss}
          normalText={<Button type='link' href='https://geneticsandfertility.com/services/ivf/' target='_blank'>https://geneticsandfertility.com/services/ivf/</Button>}
        />
        <ImageWithMultiText
          imgSrc={GainsvilleBlogCard2}
          width={300}
          boldText='Pre-Implantation Genetic Testing'
          textStyle={textCss}
          normalText={
            <Button
              type='link' 
              href='https://geneticsandfertility.com/services/pre-implantation-genetic-testing/' 
              target='_blank'
              style={{ whiteSpace: 'pre-wrap' }}
            >
              https://geneticsandfertility.com/services/pre-implantation-genetic-testing/
            </Button>}
          //normalText={<a href='https://geneticsandfertility.com/services/pre-implantation-genetic-testing/' target='_blank'>https://geneticsandfertility.com/services/pre-implantation-genetic-testing/</a>}
        />
        <ImageWithMultiText
          imgSrc={GainsvilleBlogCard3}
          width={300}
          boldText='List of All Services'
          textStyle={textCss}
          normalText={<Button type='link' href='https://geneticsandfertility.com/services/' target='_blank'>https://geneticsandfertility.com/services/</Button>}
        />
      </>
    ) : (
      <>
        <ImageWithMultiText
          width={320}
          boldText='Infertility Causes, Diagnosis, and Treatment Options'
          minorText='Anna Maria Lopez • Mar 15, 2022 • 10 min read'
          normalText='Suspendisse potenti. Sed neque augue, mattis in posuere quis, sagittis...'
        />
        <ImageWithMultiText
          width={320}
          boldText='Embarking on IVF: What to Expect'
          minorText='Bob Jones • Feb 18, 2022 • 9 min read'
          normalText='Nunc mi tortor, venenatis fermentum ipsum id, gravida lacinia carta...'
        />
        <ImageWithMultiText
          width={320}
          boldText='Managing Stress on the Fertility Journey'
          minorText='John Alvarez • Mar 18, 2022 • 16 min read'
          normalText='Praesent lobortis, lorem id elementum vehicula, sapien ipsum tincidunt...'
        />
      </>
    )
  };

  const getBanner = () => {
    if(provider.is_premium) {
      return (
        <div
          className='provider-page-content'
          style={{
            marginBottom: '3rem',
            paddingTop: '2rem',
            height: isSmallScreen ? 260 : 700,
            position: 'relative'
          }}
        >
          <Image
            alt=''
            //style={{ maxWidth: '2000px' }}
            width={'100%'}
            height={isSmallScreen ? 260 : 700}
            src={ProviderBannerPremiumGainsville}
            preview={false}
          >
          </Image>

          <div style={{ position: 'absolute', top: '30%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div style={{ textTransform: 'uppercase', marginBottom: '1rem', letterSpacing: '0.15rem', fontSize: '12px' }}>
              Fertilifind Premium Provider
            </div>
        
            <div style={headerContentStyle}>
              {provider.provider_name}
            </div>

            <FertButton type='default' size='large'>Contact</FertButton>
          </div>
          
        </div>
      );
    }

    return (
      <div
        className='provider-page-content'
        style={{
          marginBottom: provider.is_premium ? '3rem' : '0',
          paddingTop: '2rem',
          height: '400px',
          backgroundImage: 'url(' + ProviderBanner + ')'
        }}
      >
        {
          !provider.is_premium ? null : (
            <div style={{ textTransform: 'uppercase', marginBottom: '1rem', letterSpacing: '0.15rem', fontSize: '12px' }}>
              Fertilifind Premium Provider
            </div>
          )
        }
        
        <div style={headerContentStyle}>
          {provider.provider_name}
        </div>

        {provider.is_premium && <FertButton type='default' size='large'>Contact</FertButton>}
      </div>
    );
  }
  
  return (
    <div style={contentStyle}>
      {/* Header */}
      { getBanner() }


      {/* Story cards */}
      {
        !provider.is_premium ? null : (
          <div>
            <FertSpace size={isSmallScreen ? 16 : 40} style={{ flexWrap: 'wrap' }}>
              { getBlogCards() }
            </FertSpace>
          </div>
        )
      }

      <DetailSection />

      {
        relatedProviders.length > 0 && (
          <>
            <OtherOfficesSection providers={relatedProviders} />
            <Divider style={{ margin: provider.is_premium && provider.fertilifind_main_id === '0b58c7de-d392-4ef0-8c13-9790f3ee61dd' ? '24px 0 0 0' : '24px 0' }}/>
          </>
        )
      }

      {
        provider.is_premium && provider.fertilifind_main_id === '0b58c7de-d392-4ef0-8c13-9790f3ee61dd' && (
          <div style={{ backgroundColor: '#eef4f6', width: '100%', padding: '2.5rem 1rem'}}>
            <div
              style={{
                fontSize: '28px', fontWeight: 700, color: 'rgba(0, 0, 0, 0.65)', fontFamily: '"Figtree", serif', textAlign: 'center', marginBottom: '20px'
              }}
            >
              What Sets This Provider Apart
            </div>
            <div className='ff-flex-col'>
              <Image
                width={isSmallScreen ? '100%' : '75%'}
                //height={150}
                src={GainsvilleSetsApart}
                preview={false}
                style={{ marginBottom: '12px'}}
              />
            </div>
            <div style={{ width: isSmallScreen ? '100%' : '45%', margin: '0 auto', color: 'rgba(0, 0, 0, 0.65)', fontFamily: '"Public Sans", sans-serif', fontSize: '1rem', lineHeight: '1.25rem' }}>
              <p>
                Fertility Center and Applied Genetics of Florida and the offices of Julio E. Pabon, M.D., P.A., provide the most advanced treatments for 
                patients with reproductive problems. Our fertility clinic is special because we strive to provide personal attention and exceptional customer 
                service while matching or exceeding the success and technology of most clinics in the world.
              </p>
              <p>
                Unlike most IVF and Reproductive Endocrinology clinics, personal attention by the physician is the rule. Dr. Pabon is a nationally recognized 
                physician in his specialty. He has been recognized as a “Top Doctor” by U.S. News and World Report and by the Castle Connolly agency. These awards 
                are given to the best of the best as determined by the votes of other physicians.
              </p>
              <p>
                We strive to reduce the stress of fertility evaluation and treatment by keeping our patients well informed during each step of the process and 
                making sure all questions and concerns are addressed in a timely and compassionate fashion. Dr. Pabon and our team has been recognized by receiving 
                awards such as the “Compassionate Doctor” award as well as a the “Patients’ Choice” awards.
              </p>
              <p>
                In addition, we have been recognized by the American Society for Assisted Reproductive Technology as an outstanding practice by achieving success 
                rates above the national average while having few multiple pregnancies.
              </p>
              <p>
                Dr. Pabon’s good reputation has grown since he began private practice in 1995. As a result, we treat patients from all of Florida, the eastern 
                United States, Canada, Spain, and the United Kingdom. Many patients come because Florida is a second home, a vacation destination or because 
                they seek special services such as egg donation, surrogacy, pre-implantation genetic diagnosis, sex selection (family balancing) or even surgical 
                treatment for infertility such as microsurgical tubal reversals.
              </p>
            </div>
          </div>
        )
      }
    
      <SuccessRateSection provider={provider}/>

      <Divider style={{ margin: '4rem 0 0 0' }}/>

      <FinancialSection provider={provider}/>

      <div style={{ height: 0, width: 0, margin: '2rem 0' }}></div>

      <ContactForm provider={provider}/>

      <FertButton 
        className='back-to-top-button' 
        shadow={true}
        style={isBackToTopBtnVisible ? {} : { display: 'none' }}
        icon={<ArrowUpOutlined />} 
        size='large'
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      />
      
    </div>
  );
}