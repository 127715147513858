import { useState } from 'react';
import { SlimSearchProvider } from '../../atoms';
import '../../App.css';
import { Collapse } from 'antd';
import ProviderSearchDetailsCard from '../../component/ProviderSearchDetailsCard';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const minorTextColor = 'rgba(0, 0, 0, 0.65)';
// const majorTextColor = 'rgba(0, 0, 0, 0.8)';

export interface OtherOfficesSectionProps {
  providers: SlimSearchProvider[];
}

export default function OtherOfficesSection({ providers }: OtherOfficesSectionProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  
  return (
    <div style={{ paddingTop: '2rem' }}>
      <Collapse
        collapsible='header'
        expandIcon={() => <></>}
        ghost
        items={[
          {
            key: '1',
            label: <div className='other-offices-header' onClick={() => setIsExpanded(!isExpanded)}>
              { isExpanded ? <UpOutlined /> : <DownOutlined /> }
              <span style={{ marginInline: '24px', userSelect: 'none' }}>Other Office Locations</span>
              { isExpanded ? <UpOutlined /> : <DownOutlined /> }
              <div className='other-offices-expand-text '>(Click to { isExpanded ? 'collapse' : 'expand'})</div>
            </div>,
            children: <div style={{ display: 'flex', flexWrap: 'wrap', gap: '24px', alignItems: 'center', justifyContent: 'center', marginTop: '2rem' }}>
              {
                providers.map(prov => (
                  <ProviderSearchDetailsCard 
                    key={prov.fertilifind_main_id}
                    provider={prov}
                    cls='provider-search-card-other-office'
                  />
                ))
              }
            </div>,
          },
        ]}
      />
    </div>
  );
}