import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AppFooter.css'
import { Layout, Typography, Image } from 'antd';
import { FacebookFilled, InstagramOutlined, TikTokOutlined } from '@ant-design/icons';
import GrayLogo from '../images/bw logo.png';
const { Footer } = Layout;
const { Link } = Typography;

const linkStyle: React.CSSProperties = {
  color: 'rgba(0, 0, 0, 0.4)',
  fontWeight: 400,
  marginBottom: '4px',
};

const iconLinkStyle: React.CSSProperties = {
  fontSize: '1.5rem',
  paddingRight: '12px',
};

export default function AppFooter() {
  const navigate = useNavigate();

  return (
    <Footer className='app-footer'>
      <div className='app-footer-content'>

        <div className='app-footer-img-and-text-container {'>
          <div>
            <Image
              className='app-footer-logo-2'
              preview={false}
              src={GrayLogo}
              width={200}
            />
          </div>

          <div>
            <div style={linkStyle}>
              <Link onClick={() => navigate('/about')}>About us</Link>
            </div>
            <div style={linkStyle}>
              <Link onClick={() => navigate('/search')}>Access provider directory</Link>
            </div>
            {/* <div style={linkStyle}><Link>Add your business to our directory</Link></div> */}
            {/* <div><Link style={linkStyle}>Ask us a question</Link></div> */}
          </div>
        </div>

        <div className='app-footer-socials'>
          {/* <Link style={iconLinkStyle}><TwitterOutlined /></Link> */}
          <Link style={iconLinkStyle} href='https://www.instagram.com/fertilifind' target='_blank'><InstagramOutlined /></Link>
          <Link style={iconLinkStyle} href='https://www.tiktok.com/@fertilifind' target='_blank'><TikTokOutlined /></Link>
          <Link style={iconLinkStyle} href='https://www.facebook.com/groups/1087485832290138/' target='_blank'><FacebookFilled /></Link>
        </div>
      </div>
    </Footer>
  );
}