import '../App.css';
import { Space, SpaceProps } from 'antd';

export interface FertSpaceProps extends SpaceProps {
  centered?: boolean;
  style?: object;
  [x: string]: any;
};

export default function FertSpace({ direction = 'horizontal', centered = true, style={}, ...props }: FertSpaceProps) {
  return (
    <Space direction={direction} size={props.size} style={{ ...{ width: '100%', justifyContent: centered ? 'center' : '', alignItems: centered ? 'center' : '' }, ...style }}>
      {props.children}
    </Space>
  );
}