"use client";

import { useEffect, useMemo, useState } from "react";
import { useAtom, useAtomValue } from 'jotai'
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
  MapCameraChangedEvent,
  useMap,
} from "@vis.gl/react-google-maps";
import { SlimSearchProvider } from '../../atoms';
import { filteredProvidersAtom } from "../search/SearchPage";
import { Card } from "antd";
import Meta from "antd/es/card/Meta";
import { useNavigate } from "react-router-dom";
import { googleMapMetaDataAtom } from "../../atoms";
import debounce from "lodash.debounce";
import Link from "antd/es/typography/Link";
import { zipcodeEnterCountAtom } from "../search/FilterView";

export default function Maps() {
  const [filteredProviders, setFilteredProviders] = useAtom(filteredProvidersAtom);
  const [tooltipText, setToolTipText] = useState('');
  const [tooltipTextAddress, setToolTipTextAddress] = useState('');
  const [tooltipLatLng, setTooltipLatLng] = useState<google.maps.LatLngLiteral>({ lat: 0, lng: 0 });
  const [selectedProv, setSelectedProv] = useState<SlimSearchProvider | null>(null);
  const [googleMapMetaData, setGoogleMapMetaData] = useAtom(googleMapMetaDataAtom);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const boundsChangedHandler = (event: MapCameraChangedEvent) => {
    //why did we put this here?
    // setGoogleMapMetaData({
    //   defaultCenter: event.detail.center,
    //   defaultZoom: event.detail.zoom,
    //   currentBounds: event.detail.bounds,
    // });
  };

  const debouncedBoundsChangedHandler = useMemo(() => {
    return debounce(boundsChangedHandler, 400);
  }, []);

  const pinsToRender = useMemo(() => {
    const leftovers = filteredProviders.filter(prov => {
        const lat = prov.provider_latitude && Math.abs(prov.provider_latitude);
        const lng = prov.provider_longitude && Math.abs(prov.provider_longitude);
        const bounds = googleMapMetaData.currentBounds;

        if(!lat || !lng) {
          return false;
        }

        return lat > bounds.south && lat < bounds.north && lng > Math.abs(bounds.east) && lng < Math.abs(bounds.west);
      });

      const pins = leftovers.map(prov => {
          if(!prov.provider_latitude || !prov.provider_longitude) {
            return null;
          }

          const newMarker = {
            lat: prov.provider_latitude,
            lng: prov.provider_longitude,
          };

          return (
            <AdvancedMarker
              key={prov.fertilifind_main_id}
              position={newMarker}
              onClick={() => {
                setToolTipText(prov.provider_name);
                setToolTipTextAddress(prov.provider_full_address);
                setTooltipLatLng(newMarker);
                setSelectedProv(prov);
                setOpen(true);
              }}
            >
              <Pin
                background={"#096660"}
                borderColor={"#35B48A"}
                glyphColor={"#35B48A"}
              />
            </AdvancedMarker>
          );
      });

      return pins;
  }, [googleMapMetaData, filteredProviders]);

  return (
    <APIProvider apiKey="AIzaSyDC8MoxPTtpIa21-GA9uonkg9_mRaokxNs">
      <div style={{ height: "50vh", width: "100%" }}>
        <Map
          onClick={() => setOpen(false)}
          onBoundsChanged={debouncedBoundsChangedHandler}
          onZoomChanged={debouncedBoundsChangedHandler}
          defaultZoom={googleMapMetaData.defaultZoom} 
          defaultCenter={googleMapMetaData.defaultCenter}
          mapId="a78dbefa4a1ff421"
          streetViewControl={false}
          mapTypeControl={false}
          gestureHandling={'greedy'}
        >
          { pinsToRender }

          {open && (
            <InfoWindow position={tooltipLatLng} onCloseClick={() => setOpen(false)}>
              <Card bordered={false} hoverable={false}>
                <Meta title={tooltipText} description={tooltipTextAddress} />
                <Link onClick={() => navigate(`/provider/${selectedProv?.fertilifind_main_id}`)}>Go to provider page</Link>
              </Card>
            </InfoWindow>
          )}
        </Map>
      </div>
      <Thing />
    </APIProvider>
  );
}

//only used to get access to the internal google map instance object
const Thing = () => {
  const mapInstance = useMap();
  const zipcodeEnterCount = useAtomValue(zipcodeEnterCountAtom);

  useEffect(() => {
    if(zipcodeEnterCount?.lat && zipcodeEnterCount?.lng) {
      mapInstance?.panTo({ lat: zipcodeEnterCount.lat, lng: zipcodeEnterCount.lng });

      if((mapInstance?.getZoom() || 0) < 9) {
        mapInstance?.setZoom(9.5);
      }
    }
  }, [zipcodeEnterCount.count]);

  return <></>;
};
