import { useMemo, useState } from "react";
import { Input, Checkbox, Typography, message } from "antd";
import FertButton from "../../component/FertButton";
import { Provider } from "../../atoms";
const { Text, Title } = Typography;
const { TextArea } = Input;

const phoneFormat = /^\d+$/;
const emailFormat = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export interface ProviderContantFormProps {
  provider: Provider;
}

export default function ContactForm({ provider }: ProviderContantFormProps) {
  const [formAgreementChecked, setFormAgreementChecked] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [userMessage, setUserMessage] = useState('');

  const [messageApi, contextHolder] = message.useMessage();

  const isEmailFormatValid = useMemo(() => !!email && emailFormat.test(email), [email]);

  const submitButtonDisabled = useMemo(() => {
    // return !firstName || !lastName || !email || !formAgreementChecked || !isEmailFormatValid;
    return !firstName || !company || !email || !message || !formAgreementChecked || !isEmailFormatValid;
  }, [firstName, lastName, email, message, formAgreementChecked, isEmailFormatValid]);

  return (
    <div style={{ width: '100%', marginBottom: '3rem' }}>
      {contextHolder}

      <div style={{ maxWidth: '1000px', margin: '0 auto', padding: '0 2rem' }}>
        <div style={{ backgroundColor: 'rgba(0,0,0,0.05)', borderRadius: '10px', padding: '16px', width: '100%' }}>
          <Title level={2} style={{ textAlign: 'center' }}>Something not quite right on this page?</Title>
          <div className='provider-contact-form-text-container'>
            <div>
              <Text style={{ color: 'rgba(0,0,0,0.5)' }}>
                The information on our provider profiles is based on our research as of 2024. 
                If something has changed or you would like to update the information listed on your profile, 
                please contact us using the form below and we'll work with you to get it updated promptly.
              </Text>
            </div>
            {/* <div>
              <Text style={{ color: 'rgba(0,0,0,0.5)'}}>
                Whether you want
                to update your information, enhance your visibility, or
                learn more about the benefits of becoming a premium
                provider, we're just a message away.
              </Text>
            </div>
            <div>
              <Text style={{ color: 'rgba(0,0,0,0.5)'}}>
                Please fill out this
                brief form, and our team will reach out to you
                promptly to address your needs.
              </Text>
            </div> */}
          </div>

          <div className='form-fields-container'>

            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', gap: '24px' }}>
              <div>
                <Input
                  style={{ marginBottom: '16px' }}
                  type='text'
                  value={firstName}
                  placeholder='Your Name *'
                  onChange={(e) => setFirstName(e.target.value)}
                  allowClear
                />
                <Input
                  style={{ marginBottom: '16px' }}
                  type='text'
                  value={company}
                  placeholder='Company *'
                  onChange={(e) => setCompany(e.target.value)}
                  allowClear
                />
                {/* <Input
                  style={{ marginBottom: '16px' }}
                  type='text'
                  value={lastName}
                  placeholder='Last Name *'
                  onChange={(e) => setLastName(e.target.value)}
                  allowClear
                /> */}
                <Input
                  className={!!email && !isEmailFormatValid ? 'invalid-input' : ''}
                  style={{ marginBottom: '16px' }}
                  type='text'
                  value={email}
                  placeholder='Email *'
                  onChange={(e) => setEmail(e.target.value)}
                  allowClear
                />
                <Input
                  type='text'
                  style={{ marginBottom: '16px' }}
                  value={phone}
                  placeholder='Phone Number'
                  onChange={(e) => {
                    const val = e.target.value;

                    if(!val || phoneFormat.test(val)) {
                      setPhone(val);
                    }
                  }}
                  allowClear
                />

                <TextArea
                  value={userMessage}
                  rows={6}
                  maxLength={1000}
                  placeholder='Message *'
                  onChange={(e) => setUserMessage(e.target.value)}
                  allowClear
                />
                <div style={{ fontFamily: '"Public Sans", sans-serif', color: 'rgba(0,0,0,0.5)', marginTop: '8px' }}>
                  {`${1000 - userMessage.length} character${userMessage.length === 999 ? '' : 's'} remaining`}
                </div>
              </div>
              <div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '1.5rem', paddingTop: '1rem' }}>
                  <Checkbox checked={formAgreementChecked} onChange={() => setFormAgreementChecked(!formAgreementChecked)} style={{ maxWidth: '500px' }}>
                    I acknowledge that my information will be sent to Fertilifind and they will be given permission to contact me regarding this message
                  </Checkbox>
                  <FertButton
                    type='primary'
                    size='large'
                    disabled={submitButtonDisabled}
                    onClick={() => {
                      fetch("/api/providerContact", {
                        method: "POST",
                        body: JSON.stringify({
                          name: firstName,
                          company,
                          email,
                          phone,
                          message: userMessage,
                          main_id: provider.fertilifind_main_id
                        }),
                        headers: {
                          "Content-type": "application/json; charset=UTF-8",
                        },
                      })
                      .then((response) => response.json())
                      .then((json) => {
                        if(json.error) {
                          messageApi.error({ duration: 5, content: 'An error occurred, please try again later' });
                        }
                        else {
                          messageApi.success({ duration: 5, content: 'Thank you for the message!' });

                          setFirstName('');
                          setCompany('');
                          setEmail('');
                          setPhone('');
                          setUserMessage('');
                          setFormAgreementChecked(false);
                        }
                      });
                    }}
                  >
                    Submit
                  </FertButton>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

{/* <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', gap: '24px' }}>
            <div style={{  }}>
              <Input
                style={{ marginBottom: '16px' }}
                type='text'
                value={firstName}
                placeholder='First Name *'
                onChange={(e) => setFirstName(e.target.value)}
                allowClear
              />
              <Input
                style={{ marginBottom: '16px' }}
                type='text'
                value={lastName}
                placeholder='Last Name *'
                onChange={(e) => setLastName(e.target.value)}
                allowClear
              />
              <Input
                className={!!email && !isEmailFormatValid ? 'invalid-input' : ''}
                style={{ marginBottom: '16px' }}
                type='text'
                value={email}
                placeholder='Email *'
                onChange={(e) => setEmail(e.target.value)}
                allowClear
              />
              <Input
                type='text'
                value={phone}
                placeholder='Phone Number'
                onChange={(e) => {
                  const val = e.target.value;

                  if(!val || phoneFormat.test(val)) {
                    setPhone(val);
                  }
                }}
                allowClear
              />
            </div>
            <div>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '1.5rem', paddingTop: '1rem' }}>
                <Checkbox checked={formAgreementChecked} onChange={() => setFormAgreementChecked(!formAgreementChecked)} style={{ maxWidth: '500px' }}>
                  I acknowledge that my information will be sent to this clinic and they will be given permission to contact me regarding their fertility services
                </Checkbox>
                <FertButton type='primary' size='large' disabled={submitButtonDisabled}>Submit</FertButton>
              </div>
            </div>
          </div> */}