import { useEffect, useMemo, useState } from "react";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import './SearchPage.css';
import FertButton from "../../component/FertButton";
import FertInput from "../../component/field/FertInput";
import { filteredProvidersAtom } from "./SearchPage";
import { InfoCircleOutlined, QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Rate, Select, Slider, Tooltip } from "antd";
import { selectedSearchServiceAtom, SlimSearchProvider, isAppLoadingAtom, allSlimSearchProvidersAtom } from "../../atoms";
import debounce from 'lodash.debounce';

const providerNameAtom = atom('');
const zipcodeAtom = atom('');
export const zipcodeEnterCountAtom = atom<any>({ count: 0, lat: 0, lng: 0 });
const radiusAtom = atom('');
const ageAtom = atom('');
const bmiAtom = atom('');
const languagesAtom = atom([] as string[]);
const featuresAtom = atom([] as string[]);
const experiencesAtom = atom([] as string[]);
const financialsAtom = atom([] as string[]);
const consultationFeeAtom = atom(0);
const selfPayCostAtom = atom(0);
const reputationsAtom = atom([] as string[]);
const successRateAtom = atom(0);
const googleRatingAtom = atom(0);

interface ZipcodeToLatLngMap {
  [key: string]: google.maps.LatLngLiteral;
}
const zipcodeToLatLngMap: ZipcodeToLatLngMap = {};

interface FilterObject {
  type: 'basicMultiSelect' | 'customMultiSelect' | 'greaterThan' | 'lessThan' | 'between' | 'selfPay' | 'simpleSearch';
  fieldName?: string;
  lowFieldName?: string;
  highFieldName?: string;
  value: any;
}

interface FilterObjectMap {
  [key: string]: FilterObject;
}

const appliedFilterObjects: FilterObjectMap = {};

export interface FilterViewProps {
  providers: SlimSearchProvider[];
}

interface MultiSelectOption {
  label: string;
  value: string;
}

const fertilityServiceMultiSelectOptions: MultiSelectOption[] = [
  { label: 'IUI', value: 'has_iui' },
  { label: 'IVF', value: 'has_ivf' },
  { label: 'Future Ferility Preservation', value: 'has_egg_freezing,has_sperm_freezing,has_embryo_freezing' },
];

const featureMultiSelectOptions: MultiSelectOption[] = [
  { label: 'Donor egg services', value: 'accepts_donor_eggs' },
  { label: 'Donor embryo services', value: 'accepts_donor_embryos' },
  { label: 'Gestational carrier services', value: 'accepts_surrogates' },
  { label: 'Reproductive surgery', value: 'has_gyn_surgery' },
  { label: 'Genetic testing', value: 'has_genetic_testing' },
  { label: 'Gender selection allowed', value: 'has_gender_selection' },
  { label: 'Egg freezing', value: 'has_egg_freezing' },
  { label: 'Sperm freezing', value: 'has_sperm_freezing' },
  { label: 'Embryo freezing', value: 'has_embryo_freezing' },
];

const experienceMultiSelectOptions: MultiSelectOption[] = [
  { label: 'LGBTQ+ programs', value: 'has_lgbtq_programs' },
  { label: 'Independent motherhood programs', value: 'accepts_single_women' },
  { label: 'Patient portal', value: 'has_patient_portal' },
  { label: 'Acupuncture services', value: 'has_acupuncture' },
  { label: 'Nutritionist services', value: 'has_nutritionist' },
  { label: 'Counselling services', value: 'has_counseling' },
  { label: 'Open weekends', value: 'open_weekends' },
];

const financialMultiSelectOptions: MultiSelectOption[] = [
  { label: 'Accepts insurance', value: 'accepts_insurance' },
  { label: 'Financing available', value: 'has_financing' },
  { label: 'Military discount', value: 'has_military_discount' },
  { label: 'First responder discount', value: 'has_responder_discount' },
  { label: 'Teacher discount', value: 'has_teacher_discount' },
  { label: 'Risk sharing program', value: 'has_risk_share_prog' },
];

const reputationMultiSelectOptions: MultiSelectOption[] = [
  { label: 'Verified lab accreditation', value: 'has_lab_accred' },
  { label: 'Board certified specialists', value: 'has_board_cert_specialists' },
  { label: 'SART member', value: 'has_sart_cert' },
];

const getFieldNameForSuccessRateFilter = (age: string | number) => {
  let fieldName = '';
  const ageNum = parseInt(age as string);

  if(ageNum < 35) {
    fieldName = 'sart_less_thirtyfive';
  }
  else if(ageNum >= 35 && ageNum <= 37) {
    fieldName = 'sart_thirtyfive_to_seven';
  }
  else if(ageNum >= 38 && ageNum <= 40) {
    fieldName = 'sart_thirtyeight_to_forty';
  }
  else if(ageNum === 41 || ageNum === 42) {
    fieldName = 'sart_fortyone_to_two';
  }
  else {
    fieldName = 'sart_greater_fortytwo';
  }

  return fieldName;
};

export default function FilterView({ providers }: FilterViewProps) {
  
  const allSlimProviders = useAtomValue(allSlimSearchProvidersAtom);
  const [servicesSelected, setServicesSelected] = useAtom(selectedSearchServiceAtom);
  const [proivderNameSearch, setProviderNameSearch] = useAtom(providerNameAtom);
  const [zipcode, setZipcode] = useAtom(zipcodeAtom);
  const [zipcodeFinal, setZipcodeFinal] = useState('');
  const [zipcodeEnterCount, setZipcodeEnterCount] = useAtom(zipcodeEnterCountAtom);
  const [radius, setRadius] = useAtom(radiusAtom);
  const [age, setAge] = useAtom(ageAtom);
  const [bmi, setBmi] = useAtom(bmiAtom);
  const [languages, setLanguages] = useAtom(languagesAtom);
  const [features, setFeatures] = useAtom(featuresAtom);
  const [experiences, setExperiences] = useAtom(experiencesAtom);
  const [financials, setFinancials] = useAtom(financialsAtom);
  const [consultationFee, setConsultationFee] = useAtom(consultationFeeAtom);
  const [selfPayCost, setSelfPayCost] = useAtom(selfPayCostAtom);
  const [reputations, setReputations] = useAtom(reputationsAtom);
  const [successRate, setSuccessRate] = useAtom(successRateAtom);
  const [googleRating, setGoogleRating] = useAtom(googleRatingAtom);

  const [filteredProviders, setFilteredProviders] = useAtom(filteredProvidersAtom);
  const setIsAppLoading = useSetAtom(isAppLoadingAtom);

  const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();

  const createGeocoder = () => {
    if(!geocoder) {
      if(google?.maps?.Geocoder) {
        setGeocoder(new google.maps.Geocoder());
      }
      else {
        setTimeout(() => createGeocoder(), 500);
      }
    }
  }

  useEffect(() => {
    if(zipcodeEnterCount.count === 0) {
      //do nothing
    }
    else if(radius) {
      applyFilters(Number(radius));
    }
    else {
      setRadius('120701');
      applyFilters(120701);
    }
  }, [zipcodeEnterCount.count]);

  const zipcodeFieldAction = (zipcodeLocal: string) => {
    if(zipcodeLocal?.trim().length > 3) {
      const prevLatLng = zipcodeToLatLngMap[zipcodeLocal];

      if(prevLatLng) {
        setZipcodeEnterCount({ count: zipcodeEnterCount.count + 1, lat: prevLatLng.lat, lng: prevLatLng.lng });
      }
      else {
        const geocodeInput = {
          componentRestrictions: {
            country: 'US',
            postalCode: zipcodeLocal,
          }
        };

        geocoder?.geocode(geocodeInput, (results, status) => {
          if(status !== 'OK') {
            //TODO: handle
            alert('Zip code lookup was not successful for the following reason: ' + status);
          }
          else {
            if(results && results.length) {
              const latLng = {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              };

              setZipcodeEnterCount({ count: zipcodeEnterCount.count + 1, lat: latLng.lat, lng: latLng.lng });
              zipcodeToLatLngMap[zipcodeLocal] = latLng;

              if(results.length > 1) {
                console.log('Received more than one geocode result: ', results);
              }
            }
          }
        });
      }
    }
  };

  const applyFilters = async (radiusFilterValue?: number) => {
    setIsAppLoading(true);

    if(Object.keys(appliedFilterObjects).length === 0 && !radiusFilterValue) {
      setFilteredProviders(allSlimProviders.map(prov => ({  ...prov, percentMatch: 0 }))); //they should already be sorted
      setTimeout(() => setIsAppLoading(false), 1000);
      return;
    }

    try {
      const rawResponse = await fetch('/api/applyFilters', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(Object.values(appliedFilterObjects))
      });
  
      let filteredProviders = await rawResponse.json();
      // const sortedProviders: SlimSearchProvider[] = filteredProviders?.sort((a: SlimSearchProvider, b: SlimSearchProvider) => {
      //   return a.is_premium && !b.is_premium ? -1 : (!a.is_premium && b.is_premium ? 1 : 0)
      // });
  
      if(!!radiusFilterValue || !!radius) {
        filteredProviders = filterByRadius(filteredProviders, radiusFilterValue || Number(radius));
      }

      setFilteredProviders(filteredProviders);
    }
    catch(e) {
      //TODO: display error message
      console.error(e);
    }

    setIsAppLoading(false);
  };

  const filterByRadius = (arrayOfProviders: SlimSearchProvider[], radiusMeters: number) => {
    if(!radiusMeters) {
      return arrayOfProviders;
    }
    
    return arrayOfProviders.filter(prov => {
      if(!prov.provider_latitude || !prov.provider_longitude) {
        return false;
      }

      const provLatLng: google.maps.LatLngLiteral = {
        lat: prov.provider_latitude,
        lng: prov.provider_longitude,
      };

      const meters = google.maps.geometry.spherical.computeDistanceBetween({ lat: zipcodeEnterCount.lat, lng: zipcodeEnterCount.lng }, provLatLng);

      return meters <= radiusMeters;
    });
  };

  const handleRadiusChange = (value: number, localZipcodeValue: string = '') => {
    setRadius(value ? value+'' : '');

    if(zipcodeFinal || localZipcodeValue) {
      applyFilters(value);
    }
  };

  const handleSearchChange = (value: any, fieldName: string, setter: (val: any) => void) => {
    if(fieldName === 'provider_postal_code') {
      if(!(value+'')) {
        setZipcode('');
        setZipcodeFinal('');
        setZipcodeEnterCount({ count: 0, lat: 0, lng: 0 });
        setRadius('');
      }
      else {
        setZipcode(value);
      }

      return;
    }

    setter(value);
    let wasAlreadyEmpty = false;

    if(Boolean(value)) {
      appliedFilterObjects[fieldName] = {
        type: 'simpleSearch',
        fieldName,
        value
      }
    }
    else {
      if(Boolean(appliedFilterObjects[fieldName])) {
        delete appliedFilterObjects[fieldName];
      }
      else {
        wasAlreadyEmpty = true;
      }
    }
    
    if(!wasAlreadyEmpty) {
      applyFilters();
    }
  };

  const debouncedSearchChangeHandler = useMemo(() => {
    return debounce(handleSearchChange, 1000);
  }, [handleSearchChange]);

  //works on fields where the value is an array of strings
  const handleBasicMultiSelectChange = async (value: any[], fieldName: string, setter: (val: any) => void) => {
    setter(value);

    if(value?.length) {
      appliedFilterObjects[fieldName] = {
        type: 'basicMultiSelect',
        fieldName,
        value
      };
    }
    else {
      delete appliedFilterObjects[fieldName];
    }

    applyFilters();
  };

  //the value array will be an array of strings that represents the field names within the multi select option objects which should map to boolean fields on the Provider objects
  const handleCustomMultiSelectChange = (value: any[], fieldName: string, setter: (val: any) => void) => {
    setter(value);

    if(value?.length) {
      appliedFilterObjects[fieldName] = {
        type: 'customMultiSelect',
        fieldName,
        value
      };
    }
    else {
      delete appliedFilterObjects[fieldName];
    }

    applyFilters();
  };

  const handleGreaterThanFilterChange = (value: number, fieldName: string, setter: (val: any) => void) => {
    setter(value);

    if(Boolean(value) || (typeof value === 'number' && value > 0)) {
      appliedFilterObjects[fieldName] = {
        type: 'greaterThan',
        fieldName,
        value
      };
    }
    else {
      delete appliedFilterObjects[fieldName];
    }

    applyFilters();
  };

  const handleLessThanFilterChange = (value: number, fieldName: string, setter: (val: any) => void) => {
    setter(value);

    if(Boolean(value) || (typeof value === 'number' && value > 0)) {
      appliedFilterObjects[fieldName] = {
        type: 'lessThan',
        fieldName,
        value
      };
    }
    else {
      delete appliedFilterObjects[fieldName];
    }

    applyFilters();
  };

  const debouncedLessThanChangeHandler = useMemo(() => {
    return debounce(handleLessThanFilterChange, 750);
  }, [handleLessThanFilterChange]);

  const handleBetweenFilterChange = (value: number, lowFieldName: string, highFieldName: string, setter: (val: any) => void) => {
    setter(value);
    
    const combinedFieldNames = `${lowFieldName}-${highFieldName}`;

    if(Boolean(value) || (typeof value === 'number' && value > 0)) {
      appliedFilterObjects[combinedFieldNames] = {
        type: 'between',
        lowFieldName,
        highFieldName,
        value
      };

      if(lowFieldName === 'provider_min_client_age' && highFieldName === 'provider_max_client_age') {
        appliedFilterObjects['successRate'] = {
          type: 'greaterThan',
          fieldName: getFieldNameForSuccessRateFilter(value),
          value: successRate
        };
      }
    }
    else {
      delete appliedFilterObjects[combinedFieldNames];

      if(lowFieldName === 'provider_min_client_age' && highFieldName === 'provider_max_client_age') {
        delete appliedFilterObjects['successRate'];
        setSuccessRate(0);
      }
    }

    applyFilters();
  };

  const debouncedBetweenChangeHandler = useMemo(() => {
    return debounce(handleBetweenFilterChange, 750);
  }, [handleBetweenFilterChange]);

  const handleSelfPayCostFilter = (value: number) => {
    setSelfPayCost(value);

    const fieldName = servicesSelected.includes('IVF') ? 'provider_ivf_cost' : (servicesSelected.includes('Future Ferility Preservation') ? 'provider_egg_freeze_cost' : 'provider_iui_cost');

    if(Boolean(value) || (typeof value === 'number' && value > 0)) {
      appliedFilterObjects[fieldName] = {
        type: 'selfPay',
        fieldName,
        value
      };
    }
    else {
      delete appliedFilterObjects[fieldName];
    }

    applyFilters();
  };

  const debouncedSelfPayCostChangeHandler = useMemo(() => {
    return debounce(handleSelfPayCostFilter, 750);
  }, [handleSelfPayCostFilter]);

  const handleSartSuccessRateFilter = (value: number) => {
    setSuccessRate(value);

    if(Boolean(value) || value > 0) {
      appliedFilterObjects['successRate'] = {
        type: 'greaterThan',
        fieldName: getFieldNameForSuccessRateFilter(age),
        value
      };
    }
    else {
      delete appliedFilterObjects['successRate'];
    }

    applyFilters();
  };

  const debouncedSartSuccessRateChangeHandler = useMemo(() => {
    return debounce(handleSartSuccessRateFilter, 750);
  }, [handleSartSuccessRateFilter]);

  useEffect(() => {
    createGeocoder();
    applyFilters();

    return () => {
      debouncedSearchChangeHandler.cancel();
      debouncedSartSuccessRateChangeHandler.cancel();
      debouncedBetweenChangeHandler.cancel();
      debouncedLessThanChangeHandler.cancel();
      debouncedSelfPayCostChangeHandler.cancel();
    }
  }, []);

  const clearAllFilters = () => {
    setServicesSelected([]);
    setProviderNameSearch('');
    setZipcode('');
    setZipcodeFinal('');
    setRadius('');
    setAge('');
    setBmi('');
    setLanguages([]);
    setFeatures([]);
    setExperiences([]);
    setFinancials([]);
    setConsultationFee(0);
    setSelfPayCost(0);
    setReputations([]);
    setSuccessRate(0);
    setGoogleRating(0);

    Object.keys(appliedFilterObjects).forEach(key => delete appliedFilterObjects[key]);

    applyFilters();
  };

  const clearFiltersBtnDisabled = Object.keys(appliedFilterObjects).length === 0 && !radius && !proivderNameSearch && !zipcodeFinal;

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', gap: '16px' }}>
        <FertButton type={'primary'} onClick={clearAllFilters} disabled={clearFiltersBtnDisabled}>Clear All Filters</FertButton>
        
        <FertInput
          label='Clinic Name'
          type='search'
          value={proivderNameSearch}
          useClear={Boolean(proivderNameSearch)}
          onClear={() => {
            handleSearchChange('', 'provider_name', setProviderNameSearch);
          }}
          onChange={(e) => {
            if(!e.target.value) {
              handleSearchChange('', 'provider_name', setProviderNameSearch);
            }
            else {
              setProviderNameSearch(e.target.value);
            }
          }}
          onPressEnter={() => handleSearchChange(proivderNameSearch, 'provider_name', setProviderNameSearch)}
          addonBefore={<SearchOutlined />}
        />

        {/* <Divider style={{ borderColor: 'rgba(0,0,0,0.3)', margin: '12px 0' }}/> */}

        <div style={{ width: '100%', fontWeight: '600'}}>
          <div className='filter-field-label'>
            Fertility Service 
            {
              servicesSelected?.length > 0 && (
                <Button type="link" style={{ height: '0px', paddingTop: 0, paddingBottom: 0 }} onClick={() => handleCustomMultiSelectChange([], 'services', setServicesSelected)}>
                  Clear
                </Button>
              )
            }
          </div>
          <Select
            mode="multiple"
            maxTagCount={3}
            allowClear={false}
            style={{ width: '100%' }}
            onChange={(selectedValues: string[]) => handleCustomMultiSelectChange(selectedValues, 'services', setServicesSelected)}
            showSearch={false}
            value={servicesSelected}
            options={[ ...fertilityServiceMultiSelectOptions ]}
          />
        </div>

        <div style={{ width: '100%', border: '1px dashed lightgray', padding: '12px', borderRadius: '4px' }}>
          <FertInput 
            type='search'
            label='Zip code'
            value={zipcode}
            controls={false}
            useClear={Boolean(zipcodeFinal)}
            onClear={() => {
              setZipcode('');
              setZipcodeFinal('');
              setZipcodeEnterCount({ count: 0, lat: 0, lng: 0 });
              handleRadiusChange(0);
              applyFilters();
            }}
            onPressEnter={() => {
              handleSearchChange(zipcode, 'provider_postal_code', setZipcode);
              setZipcodeFinal(zipcode);
              zipcodeFieldAction(zipcode);
            }}
            onChange={(e) => {
              if(!e.target.value) {
                handleSearchChange('', 'provider_postal_code', setZipcode);
              }
              else {
                setZipcode(e.target.value);
              }
            }}
          />

          <div style={{ width: '100%', fontWeight: '600', marginTop: '12px' }}>
            <div className='filter-field-label'>
              Radius (miles)
              <span style={{ paddingTop: '3px' }}>
                <Tooltip placement='top' title='Requires a valid zip code'>
                  <Button style={{ color: 'gray' }} type='text' icon={<InfoCircleOutlined />}></Button>
                </Tooltip>
              </span>
              {
                radius?.length > 0 && Number(radius) > 0 && (
                  <Button type="link" style={{ height: '0px', paddingTop: 0, paddingBottom: 0 }} onClick={() => handleRadiusChange(0)}>
                    Clear
                  </Button>
                )
              }
            </div>
            <Select
              disabled={!zipcodeFinal}
              style={{ width: '100%' }}
              onChange={(value: string) => handleRadiusChange(Number(value) || 0)}
              value={radius+''}
              options={[
                { label: '25',  value: '40233' },
                { label: '50',  value: '80468' },
                { label: '75',  value: '120701'},
                { label: '100', value: '160934'},
                { label: '150', value: '241402'},
                { label: '200', value: '321869'},
                { label: '250', value: '402336'},
                { label: '300', value: '482803'},
              ]}
            />
          </div>
        </div>

        {/* <div style={{ width: '100%', fontWeight: '600' }}>
          <div className='filter-field-label'>Radius (miles)</div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', width: '100%' }}>
            <Slider
              style={{ flexGrow: 1 }}
              min={0}
              max={100}
              onChange={(num) => setRadius(num)}
              value={typeof radius === 'number' ? radius : 0}
            />
            <FertInput
              type='number'
              min={0}
              max={500}
              style={{ marginLeft: '16px' }}
              value={radius}
              onChange={(num) => setRadius(num as number)}
              useWidth={false}
            />
          </div>
        </div> */}

        {/* <Divider style={{ borderColor: 'rgba(0,0,0,0.3)', margin: '12px 0' }}/> */}
        
        <div style={{ width: '100%', border: '1px dashed lightgray', padding: '12px', borderRadius: '4px' }}>
          <FertInput 
            type='number'
            label='Age'
            value={age}
            controls={false}
            useClear={Boolean(age)}
            onClear={() => handleBetweenFilterChange(0, 'provider_min_client_age', 'provider_max_client_age', setAge)}
            onChange={(num) => debouncedBetweenChangeHandler(num, 'provider_min_client_age', 'provider_max_client_age', setAge)}
          />

          <div style={{ width: '100%', fontWeight: '600', marginTop: '12px' }}>
            <div>
              <span className='filter-field-label'>Min. IVF Success Rate</span>
              <span style={{ paddingTop: '3px' }}>
                <Tooltip 
                  placement='top'
                  title={
                    'IVF success rates generally decline as the age of the person whose eggs are being used increases. In order to provide the most accurate results, '
                   + 'please enter your age first then you may use the success rate filter. If you plan to use donor eggs, you may enter the age of your donor or omit using these filters.'
                  }
                  >
                  <Button style={{ color: 'gray' }} type='text' icon={<InfoCircleOutlined />}></Button>
                </Tooltip>
              </span>
              {
                  Boolean(successRate) && (
                    <Button type="link" style={{ height: '0px', paddingTop: 0, paddingBottom: 0 }} onClick={() => handleSartSuccessRateFilter(0)}>
                      Clear
                    </Button>
                  )
                }
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', width: '100%' }}>
              <Slider
                style={{ flexGrow: 1 }}
                disabled={!age}
                min={0}
                max={100}
                onChange={(num: number) => setSuccessRate(num)}
                onChangeComplete={handleSartSuccessRateFilter}
                value={typeof successRate === 'number' ? successRate : 0}
              />
              <FertInput
                type='number'
                disabled={!age}
                min={0}
                //max={100}
                style={{ marginLeft: '8px' }}
                value={successRate}
                onChange={debouncedSartSuccessRateChangeHandler}
                useWidth={false}
              />
            </div>
          </div>
        </div>

        {/* <Divider style={{ borderColor: 'rgba(0,0,0,0.3)', margin: '12px 0' }}/> */}

        <FertInput 
          type='number'
          label='BMI'
          labelExtra={
            <span>
              <Button 
                style={{ color: 'gray' }}
                type='text'
                icon={<QuestionCircleOutlined />}
                href='https://www.nhlbi.nih.gov/health/educational/lose_wt/BMI/bmicalc.htm'
                target='_blank'
              />
            </span>
          }
          value={bmi}
          controls={false}
          useClear={Boolean(bmi)}
          onClear={() => handleLessThanFilterChange(0, 'provider_max_client_bmi', setBmi)}
          onChange={(num) => debouncedLessThanChangeHandler(num, 'provider_max_client_bmi', setBmi)}
        />

        <div style={{ width: '100%', fontWeight: '600' }}>
          <div className='filter-field-label'>
            Max. Consultation Fee
            {
              Boolean(consultationFee) && (
                <Button type="link" style={{ height: '0px', paddingTop: 0, paddingBottom: 0 }} onClick={() => handleLessThanFilterChange(0, 'provider_consult_fee', setConsultationFee)}>
                  Clear
                </Button>
              )
            }
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', width: '100%' }}>
            <Slider
              style={{ flexGrow: 1 }}
              min={0}
              max={1000}
              onChange={(num: number) => setConsultationFee(num)}
              onChangeComplete={(num: number) => handleLessThanFilterChange(num, 'provider_consult_fee', setConsultationFee)}
              value={typeof consultationFee === 'number' ? consultationFee : 0}
            />
            <FertInput
              type='number'
              min={0}
              //max={1000}
              style={{ marginLeft: '8px' }}
              value={consultationFee}
              onChange={(num) => debouncedLessThanChangeHandler(num, 'provider_consult_fee', setConsultationFee)}
              useWidth={false}
            />
          </div>
        </div>

        <div style={{ width: '100%', fontWeight: '600' }}>
          <div className='filter-field-label'>
            Max. Self Pay Cost
            {
              Boolean(selfPayCost) && (
                <Button type="link" style={{ height: '0px', paddingTop: 0, paddingBottom: 0 }} onClick={() => handleSelfPayCostFilter(0)}>
                  Clear
                </Button>
              )
            }
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', width: '100%' }}>
            <Slider
              style={{ flexGrow: 1 }}
              min={0}
              max={30000}
              onChange={(num) => setSelfPayCost(num)}
              onChangeComplete={handleSelfPayCostFilter}
              value={typeof selfPayCost === 'number' ? selfPayCost : 0}
            />
            <FertInput
              type='number'
              style={{ marginLeft: '8px' }}
              value={selfPayCost}
              onChange={debouncedSelfPayCostChangeHandler}
              useWidth={false}
            />
          </div>
        </div>

        <div style={{ width: '100%', fontWeight: '600'}}>
          <div className='filter-field-label'>
            Preferred Languages
            {
              languages?.length > 0 && (
                <Button type="link" style={{ height: '0px', paddingTop: 0, paddingBottom: 0 }} onClick={() => handleBasicMultiSelectChange([], 'provider_languages', setLanguages)}>
                  Clear
                </Button>
              )
            }
          </div>
          <Select
            mode="multiple"
            maxTagCount={3}
            allowClear={false}
            style={{ width: '100%' }}
            onChange={(selectedValues: string[]) => handleBasicMultiSelectChange(selectedValues, 'provider_languages', setLanguages)}
            showSearch={false}
            value={languages}
            options={[
              { label: 'English', value: 'English' },
              { label: 'Spanish', value: 'Spanish' },
              { label: 'French',  value: 'French'  },
              { label: 'German',  value: 'German'  },
              { label: 'Dutch',   value: 'Dutch'   },
              { label: 'Korean',  value: 'Korean'  },
            ]}
          />
        </div>

        <div style={{ width: '100%', fontWeight: '600'}}>
          <div className='filter-field-label'>
            Features
            {
              features?.length > 0 && (
                <Button type="link" style={{ height: '0px', paddingTop: 0, paddingBottom: 0 }} onClick={() => handleCustomMultiSelectChange([], 'features', setFeatures)}>
                  Clear
                </Button>
              )
            }
          </div>
          <Select
            mode="multiple"
            listHeight={300}
            allowClear={false}
            style={{ width: '100%' }}
            onChange={(selectedValues: string[]) => handleCustomMultiSelectChange(selectedValues, 'features', setFeatures)}
            showSearch={false}
            value={features}
            maxTagCount={3}
            options={[ ...featureMultiSelectOptions ]}
          />
        </div>

        <div style={{ width: '100%', fontWeight: '600'}}>
          <div className='filter-field-label'>
            Patient Experience
            {
              experiences?.length > 0 && (
                <Button type="link" style={{ height: '0px', paddingTop: 0, paddingBottom: 0 }} onClick={() => handleCustomMultiSelectChange([], 'experiences', setExperiences)}>
                  Clear
                </Button>
              )
            }
          </div>
          <Select
            mode="multiple"
            maxTagCount={3}
            allowClear={false}
            style={{ width: '100%' }}
            onChange={(selectedValues: string[]) => handleCustomMultiSelectChange(selectedValues, 'experiences', setExperiences)}
            showSearch={false}
            value={experiences}
            options={[ ...experienceMultiSelectOptions ]}
          />
        </div>

        <div style={{ width: '100%', fontWeight: '600'}}>
          <div className='filter-field-label'>
            Financial
            {
              financials?.length > 0 && (
                <Button type="link" style={{ height: '0px', paddingTop: 0, paddingBottom: 0 }} onClick={() => handleCustomMultiSelectChange([], 'financials', setFinancials)}>
                  Clear
                </Button>
              )
            }
          </div>
          <Select
            mode="multiple"
            maxTagCount={3}
            allowClear={false}
            style={{ width: '100%' }}
            onChange={(selectedValues: string[]) => handleCustomMultiSelectChange(selectedValues, 'financials', setFinancials)}
            showSearch={false}
            value={financials}
            options={[ ...financialMultiSelectOptions ]}
          />
        </div>

        <div style={{ width: '100%', fontWeight: '600'}}>
          <div className='filter-field-label'>
            Reputation
            {
              reputations?.length > 0 && (
                <Button type="link" style={{ height: '0px', paddingTop: 0, paddingBottom: 0 }} onClick={() => handleCustomMultiSelectChange([], 'reputations', setReputations)}>
                  Clear
                </Button>
              )
            }
          </div>
          <Select
            mode="multiple"
            maxTagCount={3}
            allowClear={false}
            style={{ width: '100%' }}
            onChange={(selectedValues: string[]) => handleCustomMultiSelectChange(selectedValues, 'reputations', setReputations)}
            showSearch={false}
            value={reputations}
            options={[ ...reputationMultiSelectOptions ]}
          />
        </div>

        <div style={{ width: '100%', fontWeight: '600' }}>
          <div className='filter-field-label'>
            Google Rating
            {
              googleRating > 0 && (
                <Button type="link" style={{ height: '0px', paddingTop: 0, paddingBottom: 0 }} onClick={() => handleGreaterThanFilterChange(0, 'provider_google_rating', setGoogleRating)}>
                  Clear
                </Button>
              )
            }
          </div>
          <Rate 
            allowClear={true}
            allowHalf={true}
            style={{ fontSize: '32px' }}
            value={googleRating}
            onChange={(num) => handleGreaterThanFilterChange(num, 'provider_google_rating', setGoogleRating)}
          />
        </div>

      </div>
    </div>
  );
}