import React, { ReactNode, useState } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import { Divider, Layout, Radio, Space, Typography, Image } from 'antd';
import FertButton from '../component/FertButton';
import LadyOnLaptopImg from '../images/lady-on-laptop.png';
import LadyGlassesOnLaptopImg from '../images/lady-glasses-on-laptop.png';
import InfoBehindCurtains from '../images/info-behind-curtains.png';
import AppVr from '../images/app-vr.png';
import FertilifindPuzzle from '../images/fertilifind-puzzle.png';
import FertAvatarCard from '../component/FertAvatarCard';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const radioButtonStyle: React.CSSProperties = {
  userSelect: 'none',
  padding: '8px 20px 6px 20px',
  lineHeight: 1.6,
  width: 140,
  textAlign: 'center',
  height: 46,
  fontWeight: 600,
};

const contentStyle: React.CSSProperties = {
  textAlign: 'center',
  minHeight: 120,
  backgroundColor: '#FFFFFF',
  paddingBottom: '48px',
};

const minorTextStyle: React.CSSProperties = {
  textAlign: 'left',
  fontSize: '1.2rem',
  color: '#808080',
  fontFamily: '"Public Sans", sans-serif',
};

const minorTextStyleCentered: React.CSSProperties = {
  textAlign: 'center',
  fontSize: '1.2rem',
  color: '#808080',
  fontFamily: '"Public Sans", sans-serif',
};

const radioButtonOptions: string[] = ['Search', 'Unlock', 'Compare', 'Decide'];

export default function HomePage() {
  const [radioButtonValue, setRadioButtonValue] = useState<string>('Search');

  return (
    <Content style={contentStyle} className='app-body'>
      <Space direction='vertical' size={40} style={{ width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', flexWrap: 'wrap', gap: '36px' }}>
          <div style={{ maxWidth: '550px', display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='home-title'>Tailored fertility solutions are just a click away</div>
              <Paragraph style={minorTextStyle}>Looking for a one-step destination that empowers
you to take charge of your fertility? Look no further!</Paragraph>
              <Paragraph style={minorTextStyle}>Join us in reshaping how individuals access vital
information, compare providers, and make confident
decisions on their fertility journeys. </Paragraph>
              <div className='search-smarter-button-normal'>
                <Link to={`/search`}>
                  <FertButton type='primary' size='large'>Search Smarter Now</FertButton>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', maxWidth: '700px' }}>
              <Image
                preview={false}
                src={LadyOnLaptopImg}
              />
            </div>
          </div>

          <div className='search-smarter-button-mobile'>
            <Link to={`/search`}>
              <FertButton type='primary' size='large'>Search Smarter Now</FertButton>
            </Link>
          </div>
        </div>

        <Divider style={{ margin: 0 }}/>

        {/* Radio button switcher */}
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Space direction='vertical' size={0}>
            <Title level={2} style={{ textAlign: 'center', fontWeight: 700, color: 'rgb(0 0 0 / 80%)', marginBottom: '2rem', fontFamily: '"Figtree", serif' }}>
              Refine your search<br />Redefine the possibilities
            </Title>
            <div>
              <Radio.Group style={{ textAlign: 'center' }} buttonStyle='solid' size='large' value={radioButtonValue} onChange={({ target: { value } }) => setRadioButtonValue(value)}>
                {
                  radioButtonOptions.map((value, i) => (
                    <Radio.Button
                      key={i}
                      className={value === radioButtonValue ? 'radio-button-primary' : 'radio-button-light'}
                      value={value}
                      style={{ ...radioButtonStyle, color: value === radioButtonValue ? 'white' : '#35B48A' }}
                    >
                      {value}
                    </Radio.Button>
                  ))
                }
              </Radio.Group>
            </div>
          </Space>
        </div>

        {/* Parenthood plan cards and text */}
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '32px', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', flexWrap: 'wrap', gap: '36px' }}>
            <div style={{ maxWidth: '550px', display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {getRadioContent(radioButtonValue)}
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', maxWidth: '700px' }}>
                <Image
                  style={{ maxHeight: 400, maxWidth: 480 }}
                  preview={false}
                  src={getRadioImage(radioButtonValue)}
                />
              </div>
            </div>
          </div>
          {/* <ImageWithMultiText
            align='left'
            level={3}
            boldText='Interactive fertility provider directory with 40+ filters'
            normalText={
              <p>
                  Work remotely in the Cloud - anytime and
                  on any device - without the hassle of
                  uploading and downloading files.
                  <br/>
                  <br/>
                  Keep a geographically dispersed team
                  collaborating - and always in sync.
                </p>
            }
          />
          <ImageWithMultiText
            align='left'
            level={3}
            boldText='Fertility funding options and laws, all in one place'
            normalText={
              <p>
                Get collaborators onboard quickly with a single, intuitive interface.
                <br/>
                <br/>
                Curated design tools provide the functionality and flexibility that your team really needs - without any unnecessary or distracting complexity.
            </p>
            }
          />
          <ImageWithMultiText
            align='left'
            level={3}
            boldText='Educational resources you can trust'
            normalText={
              <p>
                Work within a single creative context to
                maintain your team's focus and
                momentum.
                <br/>
                <br/>
                Keep all stakeholders  building consensus
                and communicating clearly.
              </p>
            }
          /> */}
        </div>

        <Divider style={{ margin: 0 }}/>
        
        {/* Social FF reviews */}
        <div>
          <Title level={2} style={{ textAlign: 'center', fontWeight: 700, color: 'rgb(0 0 0 / 80%)', marginBottom: '36px', marginTop: '24px', fontFamily: '"Figtree", serif' }}>
            What people are saying about Fertilifind
          </Title>

          <div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center', flexWrap: 'wrap', gap: 24, marginBottom: 24 }}>
            <FertAvatarCard
              hoverable={false}
              title={'Kaci'}
              avatarSubText={<a href='https://www.instagram.com/kieran.and.mama/' target='_blank' rel='noopener noreferrer'>@kieran.and.mama</a>}
              bodyText={'Boy, do I wish your service existed 4 years ago when I started my IVF journey. Finding a clinic and gaining information took so much research, which only made the journey more daunting. This is such a vital service for the TTC'}
            />
            <FertAvatarCard
              hoverable={false}
              title={'Mallorie'}
              avatarSubText={<a href='https://www.instagram.com/mallorieowens/' target='_blank' rel='noopener noreferrer'>@mallorieowens</a>}
              bodyText={'We just started seeing IVF doctors and I did a ton of research on my own comparing and contrasting options. I would have loved to have had a service like Fertilifind to make it easier!'}
            />
            <FertAvatarCard
              hoverable={false}
              title={'Carly'}
              avatarSubText={<a href='https://www.tiktok.com/@ccarly_jo18' target='_blank' rel='noopener noreferrer'>@ccarly_jo18</a>}
              bodyText={'I wish I had known about this when I was going through our infertility and IVF journey!'}
            />
          </div>
        </div>

        <Divider style={{ margin: 0 }}/>

        <div>
          <Title level={2} style={{ textAlign: 'center', fontWeight: 700, color: 'rgb(0 0 0 / 80%)', marginBottom: '36px', marginTop: '0', fontFamily: '"Figtree", serif' }}>
            Connect With Us
          </Title>

          {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', gap: 24, marginBottom: 24 }}>
            {
              generateCardDataObjects(4).map((cardData, i) => (
                <Image
                  key={i}
                  src={`https://placekitten.com/260/180`}
                  preview={false}
                />
              ))
            }
          </div>
          <div className='hidden-on-mobile' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 24, flexWrap: 'wrap' }}>
            {
              generateCardDataObjects(4).map((cardData, i) => (
                <Image
                  key={i}
                  src={`https://placekitten.com/260/180`}
                  preview={false}
                />
              ))
            }
          </div> */}

          <div className='home-page-follow-buttons'>
            <FertButton type='primary' size='large' style={{ width: '240px' }} href='https://www.tiktok.com/@fertilifind' target='_blank'>Follow on TikTok</FertButton>
            <FertButton type='primary' size='large' style={{ width: '240px' }} href='https://www.instagram.com/fertilifind' target='_blank'>Follow on Instagram</FertButton>
            <FertButton type='primary' size='large' style={{ width: '240px' }} href='https://www.facebook.com/groups/1087485832290138/' target='_blank'>Join Facebook Group</FertButton>
          </div>
        </div>

        {/* <Divider style={{ margin: 0 }}/> */}

        {/* <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', flexWrap: 'wrap', gap: '36px' }}>
          <div style={{ maxWidth: '550px', display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='home-title' style={{ textAlign: 'center' }}>Are you a fertility provider?</div>
              <Paragraph style={minorTextStyleCentered}>Fertilifind is the best way to get in front of your audience.</Paragraph>
              <Paragraph style={minorTextStyleCentered}>Check out our sponsorship opportunities, including display
advertising, premium directory profiles, sponsored articles,
joining our team of medical advisors and more</Paragraph>
              <Link to={`/nothing`} style={{ textAlign: 'center', marginTop: '24px' }}>
                <FertButton type='primary' size='large'>Sponsorship</FertButton>
              </Link>
            </div>
          </div>
        </div> */}
      </Space>
    </Content>
  );
}

const getRadioContent = (radioValue: string): ReactNode => {
  if(radioValue === 'Search') {
    return (
      <>
        <div className='home-title'>Search <span style={{fontStyle:'italic', fontWeight:500}}>smarter</span> to find your perfect match</div>
        <Paragraph style={minorTextStyle}>Search the nation's only comprehensive directory of fertility-related providers to find your perfect match.</Paragraph>
        <Paragraph style={minorTextStyle}>
          Using more than 40 personalized filters,
          you'll discover providers tailored to
          your unique needs. Fertility treatments
          aren't one-size-fits-all, and it's time to
          stop treating them as such.
        </Paragraph>
      </>
    );
  }

  if(radioValue === 'Unlock') {
    return (
      <>
        <div className='home-title'>The <span style={{fontStyle:'italic', fontWeight:500}}>information</span> you seek, finally at your fingertips</div>
        <Paragraph style={minorTextStyle}>
          Our mission is rooted in the pursuit of better, fairer and more transparent fertility treatment. 
          We want every individual to feel informed and confident as they move toward their family-building goals. 
        </Paragraph>
        <Paragraph style={minorTextStyle}>
          You shouldn’t have to pay a fee or sit through a consultation just to view pricing and success rates – that’s why we’ve pulled the curtain back on previously hidden information through our in-depth, proprietary research.
        </Paragraph>
      </>
    );
  }

  if(radioValue === 'Compare') {
    return (
      <>
        <div className='home-title'>You <span style={{fontStyle:'italic', fontWeight:500}}>deserve</span> apples to apples</div>
        <Paragraph style={minorTextStyle}>
          It’s impossible to compare fertility providers in any meaningful way using only their company websites. 
          They display completely different information, often citing biased and outdated stats.  
        </Paragraph>
        <Paragraph style={minorTextStyle}>
          Our directory profiles examine and display the same criteria for every provider, 
          making it easy for you to compare your options side-by-side and make the best decision for your unique situation.
        </Paragraph>
      </>
    );
  }

  if(radioValue === 'Decide') {
    return (
      <>
        <div className='home-title'>Knowledge is <span style={{fontStyle:'italic', fontWeight:500}}>powerful</span></div>
        <Paragraph style={minorTextStyle}>
          Did you know that individuals seeking fertility treatments switch providers an average of three times? 
          Armed with all the information, you’ll feel empowered to choose the right provider for you from the start.   
        </Paragraph>
        <Paragraph style={minorTextStyle}>
          Embrace your unique path to parenthood and rest assured that you’ve made the right choice for your family goals.
        </Paragraph>
      </>
    );
  }

  return <></>;
};

const getRadioImage = (radioValue: string): string => {
  switch(radioValue) {
    case 'Search' : return LadyGlassesOnLaptopImg;
    case 'Unlock' : return InfoBehindCurtains;
    case 'Compare': return AppVr;
    case 'Decide' : return FertilifindPuzzle;
    default: return '';
  }
};