import { Button } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ReactNode } from 'react';

export interface FertButtonProps {
  type?: "link" | "text" | "default" | "primary" | "dashed" | undefined;
  size?: SizeType;
  children?: ReactNode;
  style?: React.CSSProperties;
  shadow?: boolean;
  href?: string;
  [x: string]: any;
};

function FertButton({ type = 'primary', size = 'middle', style = {}, shadow, href, children, ...props }: FertButtonProps) {
  const buttonStyle: React.CSSProperties = {
    fontWeight: 600,
    padding: '4px 20px',
    ...style,
  };

  if (size === 'large') {
    buttonStyle.borderRadius = '24px';
    buttonStyle.padding = '1px 40px 4px';
    buttonStyle.height = '46px';
  }

  if (type === 'link') {
    buttonStyle.fontWeight = 400;
  }

  const primaryButtonStyle: React.CSSProperties = {
    border: '1px solid #096660',
  };

  const textButtonStyle: React.CSSProperties = {
    color: '#292929',
  };

  if(!!props['icon'] && !children) {
    props['shape'] = 'circle';
    buttonStyle.padding = '';
    primaryButtonStyle.border = '';
    buttonStyle.height = '50px';
    buttonStyle.width = '50px';
  }

  if(shadow) {
    buttonStyle.boxShadow = '0 2px 8px 4px rgba(0,0,0,0.35)';
    buttonStyle.padding = '';
    primaryButtonStyle.border = '';
  }

  if(!!href) {
    buttonStyle.padding = '';
    buttonStyle.paddingTop = '8px';
  }

  const styles = {
    ...buttonStyle,
    ...(type === 'primary' ? primaryButtonStyle : {}),
    ...(type === 'text' ? textButtonStyle : {})
  };

  return (
    <Button className='fert-button' type={type} size={size} {...props} style={styles} href={href}>
      {children}
    </Button>
  );
}

export default FertButton;