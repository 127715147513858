import { ReactNode } from 'react';
import '../App.css';
import { Avatar, Card } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const { Meta } = Card;

export interface FertAvatarCardProps {
  bodyText: string;
  title: string;
  avatarSubText: ReactNode;
  hoverable?: boolean;
  [x: string]: any;
};

function FertAvatarCard({ bodyText, title, avatarSubText, hoverable=true, ...props }: FertAvatarCardProps) {
  return (
    <Card className='fert-avatar-card' loading={false} hoverable={hoverable}>
      <Meta
        avatar={<Avatar icon={<UserOutlined />} size={'large'} />}
        title={title}
        description={avatarSubText}
      />

      {bodyText}
    </Card>
  );
}

export default FertAvatarCard;