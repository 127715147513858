import './App.css';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ConfigProvider, Layout, Spin } from 'antd';
import AppFooter from './component/AppFooter';
import AppHeader from './component/AppHeader';
import { useAtom, useAtomValue } from 'jotai/react';
import {routeNamesAtom} from './index';
import { isSmallScreenAtom, isBackToTopBtnVisibleAtom, isAppLoadingAtom } from './atoms';
import { LoadingOutlined } from '@ant-design/icons';

function App() {
  const routeNames = useAtomValue(routeNamesAtom);
  const [, setIsSmallScreen] = useAtom(isSmallScreenAtom);
  const [, setBackToTopBtnVisible] = useAtom(isBackToTopBtnVisibleAtom);
  const isAppLoading = useAtomValue(isAppLoadingAtom);

  //TODO: Find a better way to send users back to home page when trying to access a non-existent page (error boundry)
  useEffect(() => {
    const fullPath = document.location.pathname as string;
    let path = fullPath.startsWith('/') ? fullPath.substring(1) : fullPath;
    path = path.substring(path.indexOf('/'));

    if (!routeNames.includes(path)) {
      //navigate('/home');
    }
  }, [routeNames]);

  const onScroll = function() {
    const scrollY = window.scrollY;
    setBackToTopBtnVisible(scrollY > 300);

    // if(bigProviderPictureRef !== null && bigProviderPictureRef.current !== null) {
    //   setHideFilterButton(scrollY <= bigProviderPictureRef.current.getBoundingClientRect().bottom);
    // }
  };

  const onResize = function() {
    setIsSmallScreen(window.innerWidth < 1100);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", onResize);

    setIsSmallScreen(window.innerWidth < 1100);

    return function cleanup() {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onResize);
    }
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: '#35B48A',
            colorPrimaryBorder: '#096660',
            borderRadius: 16,
            colorBorder: '#096660',
            lineWidth: 2,
            algorithm: true, // Enable algorithm
          },
          Input: {
            //colorPrimary: '#eb2f96',
            algorithm: true, // Enable algorithm
          },
          'Radio': {
            colorPrimary: '#35B48A',
            colorPrimaryBorder: '#096660',
            borderRadius: 16,
            colorBorder: '#096660',
          }
        },
      }}
    >
      <Layout style={{ background: 'white' }}>
        <Spin spinning={isAppLoading} size='large' fullscreen indicator={<LoadingOutlined style={{ fontSize: '96px', fontWeight: 900 }} spin />}/>
        {/* <Spin spinning={isAppLoading} size='large' fullscreen style={{ fontSize: 96 }}/> */}
        <AppHeader />

        {<Outlet />}

        <AppFooter />
      </Layout>
    </ConfigProvider>
  );
}

export default App;
