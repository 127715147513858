import React, { useEffect, useState } from 'react';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import '../../App.css';
import './SearchPage.css';
import { Drawer, Pagination } from 'antd';
// import ImageWithMultiText from '../../component/ImageWithMultiText';
import FilterView from './FilterView';
import ProviderSearchDetailsCard from '../../component/ProviderSearchDetailsCard';
import FertButton from '../../component/FertButton';
import { ArrowUpOutlined, FilterOutlined } from '@ant-design/icons';
import { isAppLoadingAtom, SlimSearchProvider, allSlimSearchProvidersAtom, isSmallScreenAtom, isBackToTopBtnVisibleAtom } from '../../atoms';
import Maps from '../maps/MapsPage';

// const minorTextColor = 'rgba(0, 0, 0, 0.65)';
// const majorTextColor = 'rgba(0, 0, 0, 0.8)';

const contentStyle: React.CSSProperties = {
  textAlign: 'center',
  minHeight: 120,
  backgroundColor: '#FFFFFF',
  // paddingTop: '3rem',
};

const providerListPageAtom = atom<number>(1);
const providerListPageSizeAtom = atom<number>(10);
const pagedFilteredProvidersAtom = atom<SlimSearchProvider[] | null>(null);
export const filteredProvidersAtom = atom<SlimSearchProvider[]>([]);

export default function SearchPage() {
  const [providers, setProviders] = useAtom(allSlimSearchProvidersAtom);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [filteredProviders, setFilteredProviders] = useAtom(filteredProvidersAtom);
  const [pagedFilteredProviders, setPagedFilteredProviders] = useAtom(pagedFilteredProvidersAtom);
  const [providerListPageNumber, setProviderListPageNumber] = useAtom(providerListPageAtom);
  const [providerListPageSize, setProviderListPageSize] = useAtom(providerListPageSizeAtom);
  const [hideFilterButton,] = useState(false);
  // const bigProviderPictureRef = useRef<HTMLDivElement | null>(null);
  // const [bookmarkSvgMeta, setBookmarkSvgMeta] = useState({ viewBox: '0 115 380 205', width: '140', height: '160' });
  const [bookmarkSvgMeta, setBookmarkSvgMeta] = useState({ viewBox: '0 0 600 675', width: '140', height: '160' });
  const [isSmallScreen, setIsSmallScreen] = useAtom(isSmallScreenAtom);
  const isBackToTopBtnVisible = useAtomValue(isBackToTopBtnVisibleAtom);
  const setIsAppLoading = useSetAtom(isAppLoadingAtom);

  const onScreenResize = function() {
    if(window.innerWidth < 1100) {
      setBookmarkSvgMeta({ viewBox: '0 125 700 600', width: '110', height: '130' });
      setIsSmallScreen(true);
    }
    else {
      setBookmarkSvgMeta({ viewBox: '0 0 600 675', width: '140', height: '160' });
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    if(!providers || !providers.length) {
      setIsAppLoading(true);

      fetch('/api/getAllProviders').then(resp => resp.json().then(data => {
        if(Array.isArray(data)) {
          //sort with premium providers at the top
          const sortedProviders: SlimSearchProvider[] = [ ...data ]?.sort((a: SlimSearchProvider, b: SlimSearchProvider) => {
            return a.is_premium && !b.is_premium ? -1 : (!a.is_premium && b.is_premium ? 1 : 0)
          });
          setProviders(sortedProviders);
          setFilteredProviders(sortedProviders);
          setPagedFilteredProviders(sortedProviders?.slice(0, providerListPageSize));

          setIsAppLoading(false);
        }
      })).catch(() => setIsAppLoading(false));
    }
  }, [setProviders, setFilteredProviders]);

  useEffect(() => paginationOnChange(providerListPageNumber), [filteredProviders]);

  useEffect(() => {
    window.addEventListener("resize", onScreenResize);

    if(providers && providers.length) {
      window.scrollTo({ top: 0 });
    }

    return function cleanup() {
      window.removeEventListener('resize', onScreenResize);
    }
  }, []);

  const paginationOnChange = (pageNum: number) => {

    let maxPageNum = Math.ceil(filteredProviders?.length / providerListPageSize);

    if(maxPageNum === 0) {
      maxPageNum = 1;
    }

    const newPageNum = pageNum > maxPageNum ? maxPageNum : pageNum;
    setProviderListPageNumber(newPageNum);
    
    const end = newPageNum * providerListPageSize;
    setTimeout(() => setPagedFilteredProviders(filteredProviders?.slice(end - providerListPageSize, end)), 1);
  };

  return (
    <div style={contentStyle}>
      {/* Header */}
      {/* <div style={providerHeadStyle}>
        <div style={{ fontSize: '36px', fontWeight: 700, marginBottom: '2rem', textAlign: 'center' }}>
          What service are you looking for?
        </div>
        <div style={{ fontSize: '18px', fontWeight: 600, marginBottom: '1rem', textAlign: 'center' }}>
          Select your desired provider category, then scroll down
          and use our intelligent filters to narrow your search.
        </div>
      </div> */}

      {/* <div ref={bigProviderPictureRef} className='provider-page-content' style={{ marginBottom: '2rem', minHeight: 300 }}>
        <div style={{ textTransform: 'uppercase', marginBottom: '1rem', letterSpacing: '0.15rem', fontSize: '12px' }}>
          Big picture here
        </div>
      </div> */}

      <div >
        <Maps />
      </div>
      {/* <div style={{ fontFamily: '"Public Sans", sans-serif', fontSize: '0.9rem', color: 'rgba(0,0,0,0.4)', paddingLeft: '4px', paddingTop: '4px' }}>
        Zoom in to see pins on the map
      </div> */}

      <div id='search-scroll-down-div' style={{ height: '12px', marginTop: '2rem' }}></div>
      <Pagination
        style={{ marginBottom: '1rem' }}
        showQuickJumper
        size={isSmallScreen ? 'small' : 'default'}
        current={providerListPageNumber}
        total={filteredProviders?.length || 0}
        showTotal={(total) => `Total of ${total} provider${total === 1 ? '' : 's'}`}
        pageSize={providerListPageSize}
        onChange={(pageNum) => paginationOnChange(pageNum)}
        onShowSizeChange={(newPageNum, newPageSize) => {
          setProviderListPageSize(newPageSize);
          setProviderListPageNumber(newPageNum);
          
          const end = newPageNum * newPageSize;
          //this needed to be put into a separate process within the event loop for some reason
          setTimeout(() => setPagedFilteredProviders(filteredProviders?.slice(end - newPageSize, end)), 1);
        }}
      />

      {/* Search filters */}
      <div className='search-provider-card-container'>
        <FertButton 
          className='open-filter-drawer-button'
          shadow={true}
          size='large'
          style={hideFilterButton || filterDrawerOpen ? { display: 'none' } : {}}
          icon={<FilterOutlined />}
          onClick={() => setFilterDrawerOpen(true)}
        >
          Filter
        </FertButton>
        {/* Filters */}
        <div className='search-filter-container'>
          <FilterView providers={providers} />
        </div>

        {/* Results */}
        <div style={{ flexGrow: 1, display: 'flex', alignItems: 'flex-end', alignContent: 'flex-start', justifyContent: 'center', flexWrap: 'wrap', gap: '24px' }}>
          {
            (pagedFilteredProviders || filteredProviders).map(provider => (
              <ProviderSearchDetailsCard
                key={provider.fertilifind_main_id}
                svgMeta={bookmarkSvgMeta}
                cls='provider-search-card'
                provider={provider}
                percentMatch={provider.percentMatch}
                //checked={providersToCompare.includes(provider.provider_name)}
                //onClick={() => navigate(`/provider/${provider.fertilifind_main_id}`)}
              />
            ))
          }
        </div>
      </div>

      <Pagination
        style={{ marginBottom: '2rem' }}
        showQuickJumper
        size={isSmallScreen ? 'small' : 'default'}
        current={providerListPageNumber}
        total={filteredProviders?.length || 0}
        showTotal={(total) => `Total of ${total} provider${total === 1 ? '' : 's'}`}
        pageSize={providerListPageSize}
        onChange={(pageNum) => paginationOnChange(pageNum)}
        onShowSizeChange={(newPageNum, newPageSize) => {
          setProviderListPageSize(newPageSize);
          setProviderListPageNumber(newPageNum);
          
          const end = newPageNum * newPageSize;
          //this needed to be put into a separate process within the event loop for some reason
          setTimeout(() => setPagedFilteredProviders(filteredProviders?.slice(end - newPageSize, end)), 1);
        }}
      />

      <FertButton 
        className='back-to-top-button' 
        shadow={true}
        style={isBackToTopBtnVisible && !filterDrawerOpen ? {} : { display: 'none' }}
        icon={<ArrowUpOutlined />} 
        size='large'
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      />
      
      <Drawer
        title="Filters"
        placement={'left'}
        closable={true}
        onClose={() => setFilterDrawerOpen(false)}
        open={filterDrawerOpen}
      >
        <FilterView providers={providers} />
      </Drawer>
    </div>
  );
}