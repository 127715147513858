import { Provider } from '../../atoms';
import '../../App.css';
import './ProviderPage.css';
import ConsultationFee from '../../images/consultation.png';
import Insurance from '../../images/insurance.png';
import MilitaryDiscount from '../../images/military.png';
import FirstResponderDiscount from '../../images/first responder.png';
import TeacherDiscount from '../../images/teacher.png';
import IuiSelfPay from '../../images/iui.png';
import IvfSelfPay from '../../images/ivf.png';
import EggFreezing from '../../images/egg freezing.png';

const minorTextColor = 'rgba(0, 0, 0, 0.65)';
//const majorTextColor = 'rgba(0, 0, 0, 0.8)';

export interface FinancialProps {
  provider: Provider;
}

export default function FinancialSection({ provider }: FinancialProps) {
  
  return (
    <>
      <div style={{ fontSize: '28px', fontWeight: 700, color: minorTextColor, marginTop: '2.5rem', fontFamily: '"Figtree", serif' }}>
        Financial Information
      </div>

      <div className='financial-icon-div-container'>
        {
          !provider.accepts_insurance ? null : (
            <div className='financial-icon-div'>
              <img alt='' style={{ margin: '0 auto' }} src={iconLookup['insurance']} width={180}/>
              <div className='minor-text-centered'>Insurance accepted</div>
            </div>
          )
        }

        {
          !provider.has_military_discount ? null : (
            <div className='financial-icon-div'>
              <img alt='' style={{ margin: '0 auto' }} src={iconLookup['militaryDiscount']} width={180}/>
              <div className='minor-text-centered'>Military discount available</div>
            </div>
          )
        }

        {
          !provider.has_responder_discount ? null : (
            <div className='financial-icon-div'>
              <img alt='' style={{ margin: '0 auto' }} src={iconLookup['firstResponderDiscount']} width={180}/>
              <div className='minor-text-centered'>First responder discount available</div>
            </div>
          )
        }

        {
          !provider.has_teacher_discount ? null : (
            <div className='financial-icon-div'>
              <img alt='' style={{ margin: '0 auto' }} src={iconLookup['teacherDiscount']} width={180}/>
              <div className='minor-text-centered'>Teacher discount available</div>
            </div>
          )
        }
      {/* </div> */}

      {/* <div className='financial-icon-div-container' style={{ marginBottom: '2.5rem' }}> */}
        {
          !provider.provider_consult_fee ? null : (
            <div className='financial-icon-div'>
              <img alt='' style={{ margin: '0 auto' }} src={iconLookup['consultation']} width={180}/>
              <div className='minor-text-centered'>Consultation fee estimated cost: ${provider.provider_consult_fee}</div>
            </div>
          )
        }

        {
          !provider.has_iui || !provider.provider_iui_cost ? null : (
            <div className='financial-icon-div'>
              <img alt='' style={{ margin: '0 auto' }} src={iconLookup['iuiSelfPay']} width={180}/>
              <div className='minor-text-centered'>IUI self-pay estimated cost: ${provider.provider_iui_cost}</div>
            </div>
          )
        }

        {
          !provider.has_ivf || !provider.provider_ivf_cost ? null : (
            <div className='financial-icon-div'>
              <img alt='' style={{ margin: '0 auto' }} src={iconLookup['ivfSelfPay']} width={180}/>
              <div className='minor-text-centered'>IVF self-pay estimated cost: ${provider.provider_ivf_cost}</div>
            </div>
          )
        }

        {
          !provider.has_egg_freezing || !provider.provider_egg_freeze_cost ? null : (
            <div className='financial-icon-div'>
              <img alt='' style={{ margin: '0 auto' }} src={iconLookup['eggFreezing']} width={180}/>
              <div className='minor-text-centered'>Egg freezing self-pay estimated cost: ${provider.provider_egg_freeze_cost}</div>
            </div>
          )
        }
      </div>
    </>
  );
}

const iconLookup = {
  consultation: ConsultationFee,
  insurance: Insurance,
  militaryDiscount: MilitaryDiscount,
  firstResponderDiscount: FirstResponderDiscount,
  teacherDiscount: TeacherDiscount,
  iuiSelfPay: IuiSelfPay,
  ivfSelfPay: IvfSelfPay,
  eggFreezing: EggFreezing,
};