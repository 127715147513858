import '../../App.css';
import './ProviderPage.css';
import { Card, Image } from 'antd';
import FertButton from '../../component/FertButton';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Provider } from '../../atoms';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useMemo } from 'react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  layout: {
    // padding: 2
  },
  font: {
    family: '"Figtree"'
  },
  plugins: {
    legend: {
      position: 'top' as const,
      labels: {
        padding: 16
      }
    },
    tooltip: {
      padding: 14,
      bodyFont: {
        family: '"Public Sans"'
      }
    }
    // title: {
    //   display: true,
    //   text: 'CDC Success Rates',
    // },
  },
  elements: {
    bar: {
      borderRadius: 4
    }
  },
  scales: {
    x: {
      ticks: {
        padding: 12,
        font: {
          family: 'Figtree',
          size: 14
        }
      },
      grid: {
        drawTicks: false,
        display: false
      }
    },
    y: {
      //suggestedMax: 50,
      ticks: {
        padding: 12,
        major: {
          // enabled: true
        },
        callback: function(value: any, index: number, ticks: any) {
          return value + '%';
        },
      },
      grid: {
        drawTicks: false
      }
    }
  },
};

const labels = ['< 35', '35-37', '38-40', '41-42', '43+'];
const natlAvgDataset = {
  label: 'National Average',
  data: [42, 30.8, 19.2, 9.2, 2.8],
  backgroundColor: '#71C7E2',
  tooltip: {
    callbacks: {
      label: function(tooltipItem: any) {
        return '  ' + tooltipItem?.dataset?.label + ': ' + tooltipItem?.formattedValue + '%';
      }
    }
  }
};

const minorTextColor = 'rgba(0, 0, 0, 0.65)';
const majorTextColor = 'rgba(0, 0, 0, 0.8)';

export interface SuccessRateProps {
  provider: Provider;
}

export default function SuccessRateSection({ provider }: SuccessRateProps) {
  const barChartData = useMemo(() => {
    return {
      labels,
      datasets: [
        {
          label: 'This Clinic',
          font: {
            family: "'Figtree'"
          },
          data: [
            provider.sart_less_thirtyfive || 0,
            provider.sart_thirtyfive_to_seven || 0,
            provider.sart_thirtyeight_to_forty || 0,
            provider.sart_fortyone_to_two || 0,
            provider.sart_greater_fortytwo || 0
          ],
          backgroundColor: '#B4DFC7',
          tooltip: {
            callbacks: {
              label: function(tooltipItem: any) {
                return '  ' + tooltipItem?.dataset?.label + ': ' + tooltipItem?.formattedValue + '%';
              }
            }
          }
        },
        natlAvgDataset,
      ],
    };
  }, [provider]);

  return (
    <div style={{ marginTop: '2rem' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '2rem', width: '100%', flexWrap: 'wrap' }}>
          {/* Left pane */}
          <div className='success-rate-left-pane'>
            <div style={{ fontSize: '28px', fontWeight: 700, color: majorTextColor, marginBottom: '8px', fontFamily: '"Figtree", serif' }}>Success Rates</div>
            
            {/* Left text card */}
            <Card
              title='Numbers Speak Volumes'
              bordered={false}
              styles={{
                header: { fontSize: '20px', fontWeight: 700, color: majorTextColor, fontFamily: '"Figtree", serif' },
                body: { paddingTop: 0, color: minorTextColor }
              }}
              style={{ backgroundColor: '#eef4f6', fontFamily: '"Public Sans", sans-serif'}}
            >
              <p>
                When choosing a fertility provider, one crucial factor to consider
                is the success rates of their assisted reproductive technology
                (ART) procedures. The success rates provide valuable insights
                into the clinic's expertise, the quality of care, and the likelihood
                of achieving a successful outcome.
              </p>
              <p>
                Success rates reflect the clinic's track record in helping
                individuals or couples achieve pregnancy and live births through
                ART procedures such as in vitro fertilization (IVF) and intrauterine
                insemination (IUI). They are typically measured by the
                percentage of cycles resulting in a positive pregnancy test,
                clinical pregnancy, or live birth.
              </p>
              <p>
                Examining success rates allows prospective patients to assess a
                clinic's experience and expertise in handling various fertility
                conditions and treatment protocols. Clinics with higher success
                rates often demonstrate a greater understanding of
                individualized patient care, the latest advancements in
                reproductive technologies, and the ability to navigate complex
                fertility challenges effectively. Moreover, success rates can
                provide realistic expectations and help manage emotional well-being during the fertility journey.
              </p>
              <p>
                When considering success rates, it's advisable to look for
                reputable sources of data, such as reports from fertility
                organizations or official governing bodies. These sources often
                provide standardized and reliable information to ensure
                transparency and accuracy in reporting.
              </p>
              <p>
                By considering success rates, individuals can make more
                informed choices and align their expectations with the clinic's
                performance. Focusing on success rates as part of the decision-making process can increase the likelihood of finding a fertility
                provider that aligns with personal goals, values, and has a proven
                track record of helping individuals achieve their dreams of
                building a family.
              </p>
            </Card>
          </div>
          
          {/* Right pane */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <div style={{ color: minorTextColor }} className='success-rate-hidden-item'>Success Rates</div>
            <div style={{ fontSize: '20px', fontWeight: 700, color: majorTextColor, fontFamily: '"Figtree", serif' }}>SART Data</div>
            <div className='bar-chart-container'>
              <Card
                bordered
                hoverable={false}
                bodyStyle={{ padding: '12px' }}
                style={{ boxShadow: '0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)' }}
              >
                <div style={{ display: 'inline-flex', width: '100%', alignItems: 'center', justifyContent: 'center', gap: '1rem', marginBottom: '1rem' }}>
                  <Card
                    bordered
                    style={{ backgroundColor: 'rgb(238, 244, 246)', fontFamily: '"Figtree", serif', fontWeight: 600 }}
                    bodyStyle={{ padding: '12px 24px' }}
                    >
                    <div style={{ color: 'rgba(0,0,0,0.65)' }}>Annual Cycles</div>
                    <div style={{ textAlign: 'center', fontSize: '16px' }}>{provider.sart_total_cycles || '???'}</div>
                  </Card>
                  <Card
                    bordered
                    style={{ backgroundColor: 'rgb(238, 244, 246)', fontFamily: '"Figtree", serif', fontWeight: 600 }}
                    bodyStyle={{ padding: '12px 24px' }}
                  >
                    <div style={{ color: 'rgba(0,0,0,0.65)' }}>National Avg</div>
                    <div style={{ textAlign: 'center', fontSize: '16px' }}>738</div>
                  </Card>
                </div>

                <Bar style={{ width: '600px' }} options={options} data={barChartData} />
              </Card>
              
            </div>
            {/* <Image
              src={'https://placekitten.com/450/250'}
              preview={false}
            /> */}

            {
              !provider.isPremium ? null : (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', maxWidth: '400px' }}>
                  <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
                    <div style={{ fontSize: '20px', fontWeight: 700, color: majorTextColor }}>
                      Clinic Data  <InfoCircleOutlined />
                    </div>
                    <Link to={`contact`}>
                      <FertButton type='primary' style={{ padding: '4px 20px 4px 16px', marginLeft: '24px' }}>Contact clinic about data</FertButton>
                    </Link>
                  </div>
                  <Image
                    src={'https://placekitten.com/450/250'}
                    preview={false}
                  />
                  <Image
                    src={'https://placekitten.com/450/250'}
                    preview={false}
                  />
                </div>
              )
            }

          </div>
        </div>
      </div>
    </div>
  );
}