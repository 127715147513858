import React, { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { isSmallScreenAtom, Provider, selectedProviderAtom } from '../../atoms';
import '../../App.css';
import { Card, Divider, Image } from 'antd';
import FertButton from '../../component/FertButton';
import FertSpace from '../../component/FertSpace';
import { EnvironmentFilled, LaptopOutlined, PhoneFilled } from '@ant-design/icons';
import ServicesImage from '../../images/provider_services_offered.png';
import FeaturesImage from '../../images/provider_features_offered.png';
import ExperiencesImage from '../../images/provider_experiences_offered.png';
import PatientStoryImage from '../../images/premium_profile_gainsville_patient_story.png';

const minorTextColor = 'rgba(0, 0, 0, 0.65)';
const majorTextColor = 'rgba(0, 0, 0, 0.8)';

const majorTextStyle: React.CSSProperties = {
  fontWeight: '700',
  fontSize: '1.2rem',
  color: majorTextColor,
  fontFamily: '"Figtree", serif',
}

const minorTextNormalStyle: React.CSSProperties = {
  color: minorTextColor,
  fontFamily: '"Public Sans", sans-serif',
};

const minorTextEightyStyle: React.CSSProperties = {
  whiteSpace: 'pre-line',
  width: '80%',
  color: minorTextColor,
  fontFamily: '"Public Sans", sans-serif',
  overflowX: 'auto',
};

const serviceHeaderStyle: React.CSSProperties = {
  fontSize: '0.85rem',
  fontWeight: 700,
  paddingLeft: '20px',
  lineHeight: '0.85rem',
};

export default function ProviderPage() {
  const provider = useAtomValue(selectedProviderAtom);
  const isSmallScreen = useAtomValue(isSmallScreenAtom);

  const detailStyle = useMemo(() => ({
    //backgroundColor: '#eaf7fb',
    backgroundColor: '#eef4f6',
    width: '100%',
    marginTop: provider.is_premium ? '1rem' : '0',
    //paddingTop: '3rem',
    //paddingBottom: '2rem',
    padding: '2.5rem 0'
  }), [provider.is_premium]);

  const servicesOffered = useMemo(() => getListOfItems(provider, 'service'), [provider]);
  const featuresOffered = useMemo(() => getListOfItems(provider, 'feature'), [provider]);
  const experiencesOffered = useMemo(() => getListOfItems(provider, 'experience'), [provider]);
  const formattedPhone = useMemo(() => {
    if(provider?.provider_phone) {
      return `(${provider.provider_phone.substring(0,3)}) ${provider.provider_phone.substring(3,6)}-${provider.provider_phone.substring(6)}`;
    }
    return '';
  }, [provider]);

  const getPatientStories = () => {
    if(provider.is_premium && provider.fertilifind_main_id === '0b58c7de-d392-4ef0-8c13-9790f3ee61dd') {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', gap: '8px', width: '100%' }}>
          <div style={majorTextStyle}>Patient Story</div>
          <div>
            <Image
              //width={300}
              //height={150}
              src={PatientStoryImage}
              preview={false}
              style={{ paddingTop: '8px' }}
            />
          </div>
          <div style={minorTextNormalStyle}>
            <p style={{ fontSize: '1rem' }}>
              “With immense gratitude and humility my wife and I walked into Dr. Pabon's office six months ago, unsure of what to expect. 
              However, our worries were quickly put at ease by the welcoming and knowledgeable staff. From the get-go, they made us feel 
              comfortable and prepared for the journey ahead. Today, we are overjoyed to share that we are expecting a healthy baby girl, 
              and we owe it all to Dr. Pabon and his incredible team. They have gone above and beyond to support us every step of the way, 
              and we cannot thank them enough for their unwavering care and expertise. We will forever be grateful for their guidance and 
              support throughout this unforgettable journey.”
            </p>
          </div>
        </div>
      );
    }
  };
  
  return (
    <div style={detailStyle}>
      <FertSpace direction='vertical' style={{ marginBottom: isSmallScreen ? '24px' : '40px' }}>
        <div style={{ fontSize: '28px', fontWeight: 700, color: minorTextColor, fontFamily: '"Figtree", serif' }}>Details</div>
        {/* <div style={{ color: 'rgba(0, 0, 0, 0.4)', fontFamily: '"Public Sans", sans-serif' }}>Nam ut rutrum ex, venenatis sollicitudin urn erat volutpat.</div> */}
      </FertSpace>

      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '42px', flexWrap: 'wrap', width: '100%' }}>
          {/* Left pane */}
          <FertSpace direction='vertical' centered={false} size={24} style={{ maxWidth: '400px' }}>
            {/* <Image
              src={'https://placekitten.com/500/350'}
              preview={false}
            /> */}

            {/* Patient Stories */}
            {
              !provider.is_premium ? null : (
                <>
                  { getPatientStories() }
                  {/* <div style={{ display: 'inline-flex', width: '100%' }}>
                    <Link to={`patient-testimonials`}>
                      <FertButton type='primary' style={{ padding: '4px 20px 4px 16px' }}>Read more patient testimonials</FertButton>
                    </Link>
                  </div> */}
                </>
              )
            }
            
            {
              provider.is_premium ? null : (
                <Card title={<p style={majorTextStyle}>Location & Contact</p>} bordered={false}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly', marginBottom: '12px' }}>
                      <div style={{ width: '20%' }}><EnvironmentFilled style={{ fontSize: '24px', paddingRight: '16px' }}/></div>
                      <div style={minorTextEightyStyle}>
                        {provider.provider_address}<br/>{provider.provider_city}, {provider.provider_state} {provider.provider_postal_code}
                      </div>
                    </div>
                    <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                      <div style={{ width: '20%' }}><LaptopOutlined style={{ fontSize: '24px', paddingRight: '16px' }}/></div>
                      <div style={minorTextEightyStyle}>{provider.provider_url && <a href={provider.provider_url} target='_blank' rel='noopener noreferrer'>{provider.provider_url}</a>}</div>
                    </div>
                    <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                      <div style={{ width: '20%' }}><PhoneFilled style={{ fontSize: '24px', paddingRight: '16px' }}/></div>
                      <div style={minorTextEightyStyle}>{formattedPhone}</div>
                    </div>
                  </div>
                </Card>
              )
            }
          </FertSpace>
          
          {/* Right pane */}
          <FertSpace direction='vertical' centered={false} size={24} style={{ maxWidth: '400px' }}>
            {/* Location and Contact card */}
            {
              !provider.is_premium ? null : (
                <Card title={<p style={majorTextStyle}>Location & Contact</p>} bordered={false} style={{ }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly', marginBottom: '12px' }}>
                      <div style={{ width: '20%' }}><EnvironmentFilled style={{ fontSize: '24px', paddingRight: '16px' }}/></div>
                      <div style={minorTextEightyStyle}>
                        {provider.provider_address}<br/>{provider.provider_city}, {provider.provider_state} {provider.provider_postal_code}
                      </div>
                    </div>
                    <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                      <div style={{ width: '20%' }}><LaptopOutlined style={{ fontSize: '24px', paddingRight: '16px' }}/></div>
                      <div style={minorTextEightyStyle}>{provider.provider_url && <a href={provider.provider_url} target='_blank' rel='noopener noreferrer'>{provider.provider_url}</a>}</div>
                    </div>
                    <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                      <div style={{ width: '20%' }}><PhoneFilled style={{ fontSize: '24px', paddingRight: '16px' }}/></div>
                      <div style={minorTextEightyStyle}>{formattedPhone}</div>
                    </div>
                  </div>
                </Card>
              )
            }

            {/* Nitty Gritty card */}
            <Card title={<p style={majorTextStyle}>The Nitty Gritty</p>} bordered={false} style={{ width: 400 }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/* Services */}
                <div style={{ display: 'inline-flex', justifyContent: 'left', gap: '12px', marginBottom: '1rem' }}>
                  <div>
                    <Image
                      width={110}
                      height={130}
                      src={ServicesImage}
                      preview={false}
                      style={{ borderRadius: '8px' }}
                    />
                  </div>
                  <div style={minorTextNormalStyle}>
                    <div style={serviceHeaderStyle}>Services Offered</div>
                    <ul>
                      { servicesOffered && servicesOffered.map(service => <li key={service}>{service}</li>) }
                    </ul>
                  </div>
                </div>

                <Divider style={{ margin: '0 0 1.5rem 0' }}/>

                {/* Features */}
                <div style={{ display: 'inline-flex', justifyContent: 'left', gap: '12px' }}>
                  <div>
                    <Image
                      width={110}
                      height={130}
                      src={FeaturesImage}
                      preview={false}
                      style={{ borderRadius: '8px' }}
                    />
                  </div>
                  <div style={minorTextNormalStyle}>
                    <div style={serviceHeaderStyle}>Features</div>
                    <ul>
                      { featuresOffered && featuresOffered.map(feature => <li key={feature}>{feature}</li>) }
                    </ul>
                  </div>
                </div>

                <Divider style={{ margin: '0 0 1.5rem 0' }}/>

                {/* Patient Experience */}
                <div style={{ display: 'inline-flex', justifyContent: 'left', gap: '12px' }}>
                  <div>
                    <Image
                      width={110}
                      height={140}
                      src={ExperiencesImage}
                      preview={false}
                      style={{ borderRadius: '8px' }}
                    />
                  </div>
                  <div style={minorTextNormalStyle}>
                    <div style={serviceHeaderStyle}>Patient Experience</div>
                    <ul>
                      { experiencesOffered && experiencesOffered.map(experience => <li key={experience}>{experience}</li>) }
                    </ul>
                  </div>
                </div>
              </div>
            </Card>
          </FertSpace>
        </div>
      </div>
    </div>
  );
}

type ProviderDetailListItemType = 'service' | 'feature' | 'experience';

const getListOfItems = (provider: Provider, type: ProviderDetailListItemType): string[] => {
  const uniqueItems = new Set();
  let map;

  switch(type) {
    case 'service': map = serviceKeyToTextMap; break;
    case 'feature': map = featureKeyToTextMap; break;
    case 'experience': map = experienceKeyToTextMap; break;
    default: throw new Error('Incorrect ProviderDetailListItemType: "' + type + '"');
  }

  Object.entries(map).forEach(([key, text]) => {
    if(!!provider[key]) {
      uniqueItems.add(text);
    }
  });

  //@ts-ignore
  return Array.from(uniqueItems);
};

const serviceKeyToTextMap = {
  has_ivf: 'IVF',
  has_iui: 'IUI',
  has_gyn_surgery: 'Gyn Surgery',
  has_egg_freezing: 'Future Fertility Preservation',
  has_sperm_freezing: 'Future Fertility Preservation',
  has_embryo_freezing: 'Future Fertility Preservation',
  accepts_donor_embryos: 'Donor Services',
  accepts_donor_eggs: 'Donor Services'
};

const featureKeyToTextMap = {
  accepts_donor_embryos: 'Accepts donor embryos',
  accepts_donor_eggs: 'Donor egg services',
  has_sperm_freezing: 'Donor sperm services',
  has_egg_freezing: 'Egg cryopreservation',
  has_embryo_freezing: 'Embryo cryopreservation services',
  accepts_surrogates: 'Gestational carrier services',
  has_lab_accred: 'Verified lab accreditation',
};

const experienceKeyToTextMap = {
  has_patient_portal: 'Patient portal',
  has_lgbtq_programs: 'LGBTQ+ friendly',
  accepts_single_women: 'Single woman friendly',
  has_acupuncture: 'Acupuncture services',
  has_nutritionist: 'Nutritionist services',
  has_counseling: 'Counselling services',
};