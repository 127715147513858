import { atom } from "jotai";

export const isSmallScreenAtom = atom<boolean>(false);
export const isBackToTopBtnVisibleAtom = atom<boolean>(false);

export const isAppLoadingAtom = atom<boolean>(false);

export const selectedSearchServiceAtom = atom<string[]>([]);

export type Provider = {
  fertilifind_main_id: string;
  provider_id: number;
  provider_name: string;
  is_premium: boolean;
  parent_provider?: string;
  provider_cat1: string;
  provider_cat2?: string | null;
  provider_cat3?: string | null;
  provider_address: string;
  provider_city: string;
  provider_state: string;
  provider_postal_code: string;
  provider_phone?: string;
  provider_fax?: string;
  provider_email?: string;
  provider_url?: string;
  accepts_insurance?: boolean;
  open_weekends?: boolean;
  mon_thu_open_hr?: string | null;
  mon_thu_close_hr?: string | null;
  fri_open_hr?: string | null;
  fri_close_hr?: string | null;
  sat_open_hr?: string | null;
  sat_close_hr?: string | null;
  sun_open_hr?: string | null;
  sun_close_hr?: string | null;
  accepts_donor_embryos?: boolean;
  accepts_donor_eggs?: boolean;
  accepts_surrogates?: boolean;
  accepts_single_women?: boolean;
  has_lgbtq_programs?: boolean;
  sart_less_thirtyfive?: number | null;
  sart_thirtyfive_to_seven?: number | null;
  sart_thirtyeight_to_forty?: number | null;
  sart_fortyone_to_two?: number | null;
  sart_greater_fortytwo?: number | null;
  sart_total_cycles?: number | null;
  provider_success_rate?: number | null;
  provider_google_rating?: number | null;
  has_gyn_surgery?: boolean;
  has_ivf?: boolean;
  has_iui?: boolean;
  has_genetic_testing?: boolean;
  has_egg_freezing?: boolean;
  has_sperm_freezing?: boolean;
  has_embryo_freezing?: boolean;
  has_gender_selection?: boolean;
  has_counseling?: boolean;
  has_military_discount?: boolean;
  has_responder_discount?: boolean;
  has_teacher_discount?: boolean;
  provider_consult_fee?: number | null;
  provider_iui_cost?: number | null;
  provider_ivf_cost?: number | null;
  provider_egg_freeze_cost?: number | null;
  has_lab_accred?: boolean;
  has_acupuncture?: boolean;
  has_nutritionist?: boolean;
  has_board_cert_specialists?: boolean;
  has_sart_cert?: boolean;
  has_patient_portal?: boolean;
  provider_min_client_age?: number | null;
  provider_max_client_age?: number | null;
  has_financing?: boolean;
  provider_languages?: string[] | null;
  provider_max_client_weight?: number | null;
  provider_max_client_bmi?: number | null;
  has_risk_share_prog?: boolean;
  provider_med_director?: string;
  provider_latitude: number | null;
  provider_longitude: number | null;

  [x: string]: any;
}

export type SlimSearchProvider = {
  fertilifind_main_id: string;
  provider_id: number;
  provider_name: string;
  is_premium: boolean;
  provider_full_address: string;
  provider_latitude?: number;
  provider_longitude?: number;
  percentMatch?: number;
}

export const allSlimSearchProvidersAtom = atom<SlimSearchProvider[]>([]);
export const allProvidersAtom = atom<Provider[]>([]);

export const selectedSlimProviderAtom = atom<SlimSearchProvider>({
  fertilifind_main_id: "",
  provider_id: 1,
  provider_name: "",
  "is_premium": false,
  provider_full_address: "",
  provider_latitude: 0,
  provider_longitude: 0,
});

export const selectedProviderAtom = atom<Provider>({
  fertilifind_main_id: "",
  provider_id: 1,
  provider_name: "",
  "is_active": true,
  "is_premium": false,
  "parent_provider": "",
  "provider_cat1": "",
  "provider_cat2": null,
  "provider_cat3": null,
  "provider_address": "",
  "provider_city": "",
  "provider_state": "",
  "provider_postal_code": "",
  "provider_phone": "",
  "provider_fax": "",
  "provider_email": "",
  "provider_url": "",
  "accepts_insurance": true,
  "open_weekends": false,
  "mon_thu_open_hr": "",
  "mon_thu_close_hr": "",
  "fri_open_hr": "",
  "fri_close_hr": "",
  "sat_open_hr": null,
  "sat_close_hr": null,
  "sun_open_hr": null,
  "sun_close_hr": null,
  "accepts_donor_embryos": true,
  "accepts_donor_eggs": true,
  "accepts_surrogates": true,
  "accepts_single_women": true,
  "has_lgbtq_programs": true,
  "sart_less_thirtyfive": null,
  "sart_thirtyfive_to_seven": null,
  "sart_thirtyeight_to_forty": null,
  "sart_fortyone_to_two": null,
  "sart_greater_fortytwo": 0,
  "sart_total_cycles": null,
  "provider_success_rate": null,
  "provider_google_rating": 0,
  "has_gyn_surgery": true,
  "has_ivf": true,
  "has_iui": true,
  "has_genetic_testing": true,
  "has_egg_freezing": true,
  "has_sperm_freezing": true,
  "has_embryo_freezing": true,
  "has_gender_selection": true,
  "has_counseling": true,
  "has_military_discount": true,
  "has_responder_discount": false,
  "has_teacher_discount": false,
  "provider_consult_fee": 0,
  "provider_iui_cost": 0,
  "provider_ivf_cost": 0,
  "provider_egg_freeze_cost": 0,
  "has_lab_accred": true,
  "has_acupuncture": false,
  "has_nutritionist": false,
  "has_board_cert_specialists": true,
  "has_sart_cert": true,
  "has_patient_portal": true,
  "provider_min_client_age": null,
  "provider_max_client_age": null,
  "has_financing": true,
  "provider_languages": null,
  "provider_max_client_weight": null,
  "provider_max_client_bmi": null,
  "has_risk_share_prog": true,
  "provider_med_director": "",
  provider_latitude: 0,
  provider_longitude: 0,
});

export interface FertMapMetaData {
  defaultCenter: google.maps.LatLngLiteral;
  defaultZoom: number;
  currentBounds: google.maps.LatLngBoundsLiteral;
};
export const googleMapMetaDataAtom = atom<FertMapMetaData>({
  defaultZoom: 4.25,
  defaultCenter: { lat: 39.828175, lng: -98.5795 },
  currentBounds: {
    east: -26.95,
    north: 57.74,
    south: 15.69,
    west: -170.21,
  }
});