import { Divider, Image, Layout } from "antd";
import InfoBehindCurtains from '../images/info-behind-curtains.png';
import '../App.css';
import Title from "antd/es/typography/Title";
import FertButton from "../component/FertButton";
import { Link } from "react-router-dom";
import { useEffect } from "react";
const { Content } = Layout;

const contentStyle: React.CSSProperties = {
  textAlign: 'center',
  minHeight: 120,
  backgroundColor: '#FFFFFF',
  paddingBottom: '48px',
  width: '100%',
  paddingInline: 0,
};

export default function AboutUsPage() {

  useEffect(() => window.scrollTo({ top: 0 }), []);

  return (
    <Content style={contentStyle} className='app-body'>
      <div className='ff-flex-row ff-space-around ff-flex-wrap-reverse' style={{ padding: '0 48px' }}>
        <div style={{ maxWidth: '600px' }}>
          <Title level={2} style={{ fontWeight: 700, color: 'rgb(0 0 0 / 70%)', marginBottom: '1.5rem', fontFamily: '"Figtree", serif' }}>
            About Us
          </Title>
          <div style={{ fontSize: '1.25rem', lineHeight: '1.75rem' }} className='ff-body-font'>
            We're passionate about empowering individuals and
            couples on their fertility journey. Founded with a mission to
            simplify the process of finding the perfect fertility provider,
            our platform offers a comprehensive solution tailored to
            your unique needs and preferences. With advanced
            technology, proprietary data and a commitment to
            transparency, we're reshaping the landscape of fertility
            care, one provider match at a time.
          </div>
          <div style={{ marginTop: '32px' }}>
            <Link to={`/search`}>
              <FertButton type='primary' size='large'>Search Smarter Now</FertButton>
            </Link>
          </div>
        </div>
        <div style={{ minWidth: '500px' }}>
          <Image
            style={{ maxHeight: 360, maxWidth: 480 }}
            preview={false}
            src={InfoBehindCurtains}
          />
        </div>
      </div>

      <Divider/>

      <Title level={2} style={{ fontWeight: 700, color: 'rgb(0 0 0 / 70%)', marginTop: '4rem', marginBottom: '1.5rem', fontFamily: '"Figtree", serif' }}>
        Our Story
      </Title>

      <div className='ff-body-font ff-flex-col' style={{ fontSize: '1.2rem', gap: '24px' }}>
        <div className='about-our-story-paragraphs' style={{ maxWidth: '1100px', lineHeight: '1.75rem', padding: '0 48px' }}>
          <div>
            Meet Ty and Julie, the husband and wife duo behind Fertilifind. Their journey to creating this revolutionary
            platform comes from deeply personal experience. Struggling with infertility themselves, Ty and Julie found the
            process overwhelming and fragmented. They found themselves wishing for a comprehensive resource that
            would provide them with everything they needed in one place – one true source of information.
          </div>
          <div>
            What shocked them the most was the lack of transparency in the fertility industry. Critical information is often
            hidden behind paywalls, and success rates are often presented in a misleading way in fertility advertising and
            marketing. Recognizing that many individuals and couples pay for fertility treatments out of pocket and thus
            behave more like consumers than typical patients, they were surprised that the industry hadn't evolved to
            reflect this and allow for easy comparison shopping.
          </div>
          <div>
            Moreover, they were disappointed by the lack of guidance from their primary care doctors and gynecologist
            after being diagnosed with unexplained infertility. Left to navigate the journey on their own, they tried multiple
            doctors before finding one they felt comfortable with – a journey they soon realized was shared by many
            others.
          </div>
          <div>
            Ty's background in software engineering and Julie's in marketing for the healthcare industry provided the
            perfect combination of skills to create a solution. Fueled by their personal experience and driven by data and
            technology, they set out to fill the gap they had identified in the market.
          </div>
          <div>
            Their mission? To empower individuals and couples on their fertility journey by providing them with the tools
            and information they need to make informed decisions. Ty and Julie are thrilled to offer Fertilifind to users at no
            cost. Their hope is that this tool makes the infertility process just the *tiniest* bit easier for those going through
            it.
          </div>
        </div>
      </div>

      <Divider/>

      <Title level={2} style={{ fontWeight: 700, color: 'rgb(0 0 0 / 70%)', marginTop: '4rem', marginBottom: '1.5rem', fontFamily: '"Figtree", serif' }}>
        Our Team
      </Title>

    </Content>
  );
}