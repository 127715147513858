import { SlimSearchProvider } from '../atoms';
import { Link } from 'react-router-dom';
import '../App.css';
import './ProviderSearchDetailsCard.css';
import FertButton from './FertButton';
import { Card } from 'antd';
import { ReactComponent as BookmarkSvg } from './bookmark-blank.svg';
import { ReactComponent as PremProvSvg } from './premium-provider.svg';
import SearchCardBackgroundPng from '../images/provider-search-card-background.png';
import DoubleRightOutlined from '@ant-design/icons/lib/icons/DoubleRightOutlined';

export interface ProviderSearchDetailsCardProps {
  provider: SlimSearchProvider;
  cls?: string;
  onClick?: () => void;
  svgMeta?: any;
  percentMatch?: number;
  [x: string]: any;
};

export default function ProviderSearchDetailsCard({ provider, cls, onClick, percentMatch, svgMeta }: ProviderSearchDetailsCardProps) {

  return (
    <Card
      className={cls}
      bordered
      hoverable
      //style={{ boxShadow: '2px 2px 8px 3px lightgray' }}
      style={{ border: '1px solid lightgray' }}
      styles={{
        header: { padding: '12px' },
        body:   { padding: '0', cursor: 'default' }
      }}
    >

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: '100%', cursor: 'default' }}>
        <div>
          <img style={{ borderRadius: '8px' }} alt="example" src={SearchCardBackgroundPng} />

          {
            !percentMatch ? null : (
              <div className='bookmark-match'>
                <BookmarkSvg className='' viewBox={svgMeta.viewBox} width={svgMeta.width} height={svgMeta.height} />
                <div className='match-percentage-text'>{`${percentMatch}%`}<br></br>Match</div>
              </div>
            )
          }

          <div className='search-card-provider-details'>
            <div className='search-card-provider-name'>{provider.provider_name}</div>
            <div className='search-card-provider-location'>{provider.provider_full_address}</div>
            <Link to={`/provider/${provider.fertilifind_main_id}`}>
              <FertButton className='search-card-more-btn'>MORE <DoubleRightOutlined /></FertButton>
            </Link>
          </div>

          { provider.is_premium && <PremProvSvg className='prem-prov-banner' /> }
          
        </div>
        {/* <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}> */}
          {/* <FertButton type={'link'}>Details</FertButton> */}
          {/* <Link to={`/provider/${providerMainId}`}>Provider Page</Link> */}
          {/* <Checkbox onChange={handleChange} checked={checked}>Compare</Checkbox> */}
        {/* </div> */}
      </div>

    </Card>
  );
}