import { ReactNode } from 'react';
import { Button, Input, InputNumber, InputProps, Space } from 'antd';
import Search from 'antd/es/input/Search';

export interface FertInputProps extends InputProps {
  type?: 'text' | 'number' | 'search';
  label?: string;
  style?: React.CSSProperties;
  onChange?: (arg: any) => any;
  onClear?: (arg: any) => any;
  onPressEnter?: (arg: any) => any;
  controls?: boolean;
  useClear?: boolean;
  min?: any;
  max?: any;
  useWidth?: boolean;
  addonBefore?: ReactNode;
  addonAfter?: ReactNode;
  disabled?: boolean;
  labelExtra?: ReactNode;
  [x: string]: any;
};

function FertInput({
  type = 'text',
  label,
  style = {},
  onChange = () => {},
  onClear = () => {},
  onPressEnter = () => {},
  controls = true,
  useClear = false,
  min,
  max,
  useWidth = true,
  addonBefore,
  addonAfter,
  disabled=false,
  labelExtra,
  ...props
}: FertInputProps) {
  let field;

  const styles = {
    width: useWidth ? '100%' : '',
    ...style
  };

  if(type === 'text') {
    field = (
      <Input
        style={styles}
        value={props.value as string}
        allowClear={true}
        onChange={onChange}
        addonBefore={addonBefore}
      />
    );
  }
  else if(type === 'search') {
    field = (
      <Space.Compact style={{ width: '100%' }}>
        <Input 
          style={styles}
          spellCheck={false}
          value={props.value as string}
          allowClear={false}
          addonBefore={addonBefore}
          onChange={onChange}
          onKeyDown={(e) => {
            if(e.key === 'Enter' || e.code === 'Enter') {
              onPressEnter('');
            }
          }}
        />
        <Button type="primary" onClick={onPressEnter} disabled={!props.value} style={{ border: 'none'}}>Submit</Button>
      </Space.Compact>
    );
  }
  else if(type === 'number') {
    field = (
      <InputNumber
        style={styles}
        value={props.value as string}
        onChange={onChange}
        controls={controls}
        min={min}
        max={max}
        addonBefore={addonBefore}
        disabled={disabled}
      />
    );
  }

  return (
    <div style={ useWidth ? { width: '100%'  } : {}}>
      {
        label && (
          <div style={{ fontWeight: '600', marginBottom: '8px', fontFamily: '"Public Sans", sans-serif' }}>
            {label} {!!labelExtra && labelExtra}
            {
              useClear && (
                <Button type="link" style={{ height: '0px', paddingTop: 0, paddingBottom: 0 }} onClick={onClear}>
                  Clear
                </Button>
              )
            }
          </div>
        )
      }

      { field }
    </div>
  );
}

export default FertInput;